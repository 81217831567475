import { Box, Typography, Stack, Button } from "@mui/material";
import { memo, useEffect, useCallback, useState } from "react";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { get } from "../../../services/api";
import {
  useMaintenanceCheckInterval,
  useMaintenanceMaxAttempts,
} from "../../../services/hooks/useConfig";
import { useNavigate } from "react-router-dom";

export const MaintenanceMode = memo(() => {
  const [isChecking, setIsChecking] = useState(false);
  const [autoAttempts, setAutoAttempts] = useState(0);
  const [isInMaintenance, setIsInMaintenance] = useState(true);
  const checkInterval = useMaintenanceCheckInterval();
  const maxAttempts = useMaintenanceMaxAttempts();
  const navigate = useNavigate();

  const checkAvailability = useCallback(
    async (isAutomatic = false) => {
      setIsChecking(true);
      try {
        await get("/v3/briefs");
        setIsInMaintenance(false);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        navigate("/", { replace: true });
      } catch (error) {
        if (error.message === "MAINTENANCE_MODE") {
          setIsInMaintenance(true);
          if (isAutomatic) {
            setAutoAttempts((prev) => prev + 1);
          }
        } else {
          console.error("Error checking availability:", error);
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
      } finally {
        setIsChecking(false);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (!isInMaintenance || autoAttempts >= maxAttempts) {
      return;
    }

    const interval = setInterval(() => {
      checkAvailability(true);
    }, checkInterval * 60 * 1000);

    return () => clearInterval(interval);
  }, [
    checkAvailability,
    checkInterval,
    autoAttempts,
    maxAttempts,
    isInMaintenance,
  ]);

  const handleManualCheck = () => {
    checkAvailability(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="background.default"
    >
      <Stack spacing={4} alignItems="center" maxWidth={600} padding={3}>
        <Box
          component="img"
          src={process.env.PUBLIC_URL + "/logo-full.png"}
          alt="Logo"
          sx={{ width: 200, marginBottom: 2 }}
        />
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight="600"
          color="primary"
        >
          System Maintenance
        </Typography>

        <Box
          sx={{
            borderRadius: 2,
            bgcolor: "background.paper",
            p: 3,
            boxShadow: 1,
          }}
        >
          <Typography
            textAlign="center"
            variant="h6"
            sx={{
              fontSize: "1.1rem",
              lineHeight: 1.6,
            }}
          >
            We're currently performing system updates to improve your
            experience. Please check back in a few minutes.
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Stack spacing={3} alignItems="center">
            {autoAttempts < maxAttempts ? (
              <Stack spacing={2} alignItems="center">
                <Typography
                  textAlign="center"
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    opacity: 0.9,
                  }}
                >
                  The system will automatically check availability every{" "}
                  {checkInterval} minutes
                </Typography>
                <Typography
                  textAlign="center"
                  variant="body2"
                  sx={{
                    fontSize: "0.95rem",
                    opacity: 0.75,
                  }}
                >
                  ({autoAttempts}/{maxAttempts} automatic attempts completed)
                </Typography>
                <Typography
                  textAlign="center"
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    opacity: 0.9,
                    fontWeight: "500",
                  }}
                >
                  You can also use the button below to check manually at any
                  time
                </Typography>
              </Stack>
            ) : (
              <Stack spacing={2} alignItems="center">
                <Typography
                  textAlign="center"
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    opacity: 0.9,
                  }}
                >
                  Automatic checks have been disabled
                </Typography>
                <Typography
                  textAlign="center"
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    opacity: 0.9,
                    fontWeight: "500",
                  }}
                >
                  You can still check manually using the button below
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>

        <Button
          variant="contained"
          size="large"
          startIcon={<RefreshIcon />}
          onClick={handleManualCheck}
          disabled={isChecking}
          sx={{ mt: 2 }}
        >
          {isChecking ? "Checking..." : "Check Now"}
        </Button>
      </Stack>
    </Box>
  );
});

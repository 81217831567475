import {
  Box,
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
} from "@mui/icons-material";
import { PaymentModal, UserAgreement } from "../../components";
import {
  Suspense,
  memo,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useSetAtom } from "jotai";
import { helpDialogOpenAtom } from "../../components/HelpDialog/HelpDialog";

import { HelpOutline as HelpIcon } from "@mui/icons-material";

import { DefaultNavigation } from "./DefaultNavigation";
import Grid from "@mui/material/Unstable_Grid2";
import { MobileNavigation } from "./MobileNavigation";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import { sidebarOpenAtom } from "../../../services/store";
import { useAtom } from "jotai";
import { useAuth } from "../../../services/auth";
import { useEffectOnce } from "../../../services/hooks";
import { useIntercom } from "react-use-intercom";
import { useSuspenseQuery } from "@tanstack/react-query";
import { userOptions } from "../../../services/queries";
import { HelpDialog } from "../../components/HelpDialog/HelpDialog";
import { VideoModal } from "../../components/VideoModal/VideoModal";
import { PlayCircleOutline as VideoIcon } from "@mui/icons-material";
import { getVideoContent } from "../../../services/videos";
import {
  doctrinaOnboardingState,
  judgesOnboardingState,
  debriefOnboardingState,
  ciceroOnboardingState,
  homeOnboardingState,
} from "../../../services/store";

export const Default = memo(
  ({
    children,
    aside,
    asideIntiallyOpen = false,
    handleAsideSideEffect = false,
    navigationRef,
    videoButtonRef,
    helpButtonRef,
  }) => {
    const { boot } = useIntercom();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const location = useLocation();
    const navigate = useNavigate();
    const { handleLogout, handleCheckAuth } = useAuth();
    const [asideOpen, setAsideOpen] = useAtom(sidebarOpenAtom);

    const [showTooltip, setShowTooltip] = useState(false);
    const [videoModalOpen, setVideoModalOpen] = useState(false);

    const [doctrinaOnboarding, setDoctrinaOnboarding] = useAtom(
      doctrinaOnboardingState
    );
    const [judgesOnboarding, setJudgesOnboarding] = useAtom(
      judgesOnboardingState
    );
    const [debriefOnboarding, setDebriefOnboarding] = useAtom(
      debriefOnboardingState
    );
    const [ciceroOnboarding, setCiceroOnboarding] = useAtom(
      ciceroOnboardingState
    );
    const [homeOnboarding, setHomeOnboarding] = useAtom(homeOnboardingState);

    const handleAsideOpen = useCallback(() => {
      setAsideOpen(!asideOpen);
      setShowTooltip(false);

      if (handleAsideSideEffect) {
        handleAsideSideEffect();
      }
    }, [asideOpen, handleAsideSideEffect, setAsideOpen]);

    const logout = useCallback(async () => {
      await handleLogout();
      navigate("/login");
    }, [handleLogout, navigate]);

    const { data: user } = useSuspenseQuery(userOptions());

    useEffectOnce(() => {
      boot({
        alignment: "left",
        backgroundColor: theme.palette.primary.main,
        actionColor: theme.palette.primary.main,
        email: user.email,
        userHash: user.intercom_hash,
        verticalPadding: 180,
      });
    });

    useEffect(() => {
      setAsideOpen(asideIntiallyOpen);
    }, [asideIntiallyOpen, setAsideOpen]);

    useEffect(() => {
      const check = setInterval(async () => {
        const response = await handleCheckAuth({ bypassCache: true });
        const { status } = response;

        if (status === "failure") {
          await logout();
        }
      }, 10000);

      return () => clearInterval(check);
    }, [handleCheckAuth, logout, navigate]);

    useEffect(() => {
      hotjar.stateChange(location?.pathname);
    }, [location?.pathname]);

    useEffect(() => {
      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        ReactGA.pageview(location.pathname + location.search);
      }
    }, [location?.pathname, location?.search]);

    const setHelpDialogOpen = useSetAtom(helpDialogOpenAtom);

    const handleHelpOpen = () => setHelpDialogOpen(true);

    const videoContent = useMemo(
      () => getVideoContent(location.pathname),
      [location.pathname]
    );

    const isDoctrinaPage = location.pathname === "/doctrina";
    const isJudgesPage = location.pathname === "/judges";
    const isDebriefPage = location.pathname === "/debrief";
    const isCiceroPage = location.pathname === "/cicero";
    const [isOnboardingLoading, setIsOnboardingLoading] = useState(false);
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
      if (shouldScroll) {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });

        setTimeout(() => {
          setShouldScroll(false);
          setIsOnboardingLoading(false);
          if (isDoctrinaPage) {
            setDoctrinaOnboarding({
              isActive: true,
              currentStep: 1,
            });
          } else if (isJudgesPage) {
            setJudgesOnboarding({
              isActive: true,
              currentStep: 1,
            });
          } else if (isDebriefPage) {
            setDebriefOnboarding({
              isActive: true,
              currentStep: 1,
            });
          } else if (isCiceroPage) {
            setCiceroOnboarding({
              isActive: true,
              currentStep: 1,
            });
          } else if (location.pathname === "/") {
            setHomeOnboarding({
              isActive: true,
              currentStep: 1,
            });
          }
        }, 1000);
      }
    }, [
      shouldScroll,
      setDoctrinaOnboarding,
      setJudgesOnboarding,
      setDebriefOnboarding,
      setCiceroOnboarding,
      setHomeOnboarding,
      isDoctrinaPage,
      isJudgesPage,
      isDebriefPage,
      isCiceroPage,
      location.pathname,
    ]);

    const handleStartOnboarding = () => {
      setIsOnboardingLoading(true);
      const currentScroll = window.pageYOffset;

      if (currentScroll > 0) {
        setShouldScroll(true);
      } else {
        setIsOnboardingLoading(false);
        if (isDoctrinaPage) {
          setDoctrinaOnboarding({
            isActive: true,
            currentStep: 1,
          });
        } else if (isJudgesPage) {
          setJudgesOnboarding({
            isActive: true,
            currentStep: 1,
          });
        } else if (isDebriefPage) {
          setDebriefOnboarding({
            isActive: true,
            currentStep: 1,
          });
        } else if (isCiceroPage) {
          setCiceroOnboarding({
            isActive: true,
            currentStep: 1,
          });
        } else if (location.pathname === "/") {
          setHomeOnboarding({
            isActive: true,
            currentStep: 1,
          });
        }
      }
    };

    return (
      <Box paddingX={aside ? 3 : 0} position="relative">
        {!md && aside && (
          <Stack
            alignItems="center"
            backgroundColor="primary.main"
            borderRadius={1}
            position="fixed"
            height={30}
            justifyContent="center"
            right={30}
            bottom={30}
            width={30}
            sx={{ transform: "translateX(50%)" }}
            zIndex={14}
          >
            <Tooltip
              title={asideOpen ? "Close ReWrite" : "Open ReWrite"}
              placement="bottom"
              open={showTooltip}
              onClose={() => setShowTooltip(false)}
              onOpen={() => setShowTooltip(true)}
            >
              <IconButton
                onClick={handleAsideOpen}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                {!asideOpen ? (
                  <NavigateBeforeIcon sx={{ color: "#fff", fontSize: 30 }} />
                ) : (
                  <NavigateNextIcon sx={{ color: "#fff", fontSize: 30 }} />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        <Grid container>
          <Grid xs={12} md={2}>
            <Stack
              position="sticky"
              top={0}
              paddingBottom={[0, 5]}
              paddingTop={[2, 5]}
              paddingRight={3}
              paddingLeft={!aside ? 3 : 0}
              height={["auto", "auto", "100vh"]}
              justifyContent="space-between"
              zIndex={10}
            >
              <Stack
                direction={["row", "row", "column"]}
                alignItems={["center", "flex-start"]}
                justifyContent={["space-between"]}
                flex={1}
                gap={3}
              >
                <Box position="relative" zIndex={9}>
                  <Link to="/">
                    <Box
                      as="img"
                      src={process.env.PUBLIC_URL + "/logo-full.png"}
                      alt="Analytica"
                      display="block"
                      height="auto"
                      width={150}
                      maxWidth="100%"
                    />
                  </Link>
                </Box>
                {!md && (
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    {location.pathname !== "/" && <MobileNavigation />}
                  </Stack>
                )}
                {md && <DefaultNavigation ref={navigationRef} />}
              </Stack>
              {md && (
                <Stack gap={1}>
                  {(isDoctrinaPage ||
                    isJudgesPage ||
                    isDebriefPage ||
                    isCiceroPage ||
                    location.pathname === "/") && (
                    <Button
                      variant="text"
                      size="small"
                      onClick={handleStartOnboarding}
                      disabled={isOnboardingLoading}
                      sx={{
                        color: "text.secondary",
                        fontSize: "0.9rem",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                        padding: "8px 24px",
                        borderRadius: 2,
                      }}
                    >
                      {isOnboardingLoading ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : location.pathname === "/" ? (
                        "View Home Onboarding"
                      ) : isDoctrinaPage ? (
                        "View Doctrina Onboarding"
                      ) : isJudgesPage ? (
                        "View Judges Onboarding"
                      ) : isDebriefPage ? (
                        "View DeBrief Onboarding"
                      ) : (
                        "View Cicero Onboarding"
                      )}
                    </Button>
                  )}
                  {videoContent && (
                    <Button
                      ref={videoButtonRef}
                      variant="text"
                      size="small"
                      startIcon={<VideoIcon />}
                      onClick={() => setVideoModalOpen(true)}
                    >
                      {videoContent.title}
                    </Button>
                  )}
                  <Button
                    ref={helpButtonRef}
                    variant="text"
                    size="small"
                    startIcon={<HelpIcon />}
                    onClick={handleHelpOpen}
                  >
                    Help
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    display={["none", "block"]}
                    onClick={logout}
                  >
                    LogOut
                  </Button>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid xs={12} md={aside && asideOpen ? 7 : 10}>
            <Box
              borderLeft="1px solid grey"
              borderRight="1px solid grey"
              borderColor="grey.100"
              minHeight="100vh"
              position="relative"
            >
              {children}
            </Box>
          </Grid>
          <Grid
            xs={asideOpen && aside ? 12 : 0}
            md={asideOpen && aside ? 3 : 0}
          >
            {aside && (
              <Box
                backgroundColor={["white", "transparent"]}
                height="100vh"
                position={["fixed", "sticky"]}
                width={[asideOpen ? "100%" : 0, "auto"]}
                top={0}
                left={0}
                zIndex={11}
              >
                {md && (
                  <Stack
                    alignItems="center"
                    backgroundColor="primary.main"
                    top={[60, 20]}
                    borderRadius={1}
                    position="absolute"
                    height={30}
                    justifyContent="center"
                    left={[-20, -30]}
                    width={30}
                    sx={{ transform: "translateX(50%)" }}
                    zIndex={11}
                  >
                    <Tooltip
                      title={asideOpen ? "Close ReWrite" : "Open ReWrite"}
                      placement="bottom"
                      open={showTooltip}
                      onClose={() => setShowTooltip(false)}
                      onOpen={() => setShowTooltip(true)}
                    >
                      <IconButton onClick={handleAsideOpen}>
                        {!asideOpen ? (
                          <NavigateBeforeIcon
                            sx={{ color: "#fff", fontSize: 30 }}
                          />
                        ) : (
                          <NavigateNextIcon
                            sx={{ color: "#fff", fontSize: 30 }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
                {asideOpen && (
                  <Box
                    height="100%"
                    overflow="scroll"
                    width="100%"
                    marginRight={[0, -3]}
                  >
                    <Suspense>{aside}</Suspense>
                  </Box>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
        <PaymentModal />
        {user?.user_agreement_signature_needed &&
          !user?.user_registration_needed && <UserAgreement />}
        <HelpDialog />
        {videoContent && (
          <VideoModal
            open={videoModalOpen}
            onClose={() => setVideoModalOpen(false)}
            title={videoContent.title}
            videoUrl={videoContent.videoUrl}
          />
        )}
      </Box>
    );
  }
);

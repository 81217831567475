import React, { useState } from "react";
import {
  Paper,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Box,
  Divider,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  Folder as FolderIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { TextField } from "../TextField";
import { useForm } from "react-hook-form";
import {
  useUpdateCicero,
  useDeleteCicero,
} from "../../../services/models/courses";
import { useContainerQuery } from "react-container-query";
import { useQuery } from "@tanstack/react-query";
import { ciceroOptions } from "../../../services/queries";

const query = {
  "width-between-500-and-820": {
    minWidth: 500,
    maxWidth: 820,
  },
};

export const CiceroCard = ({
  id,
  title,
  activity_title,
  activity_id,
  context_id,
  case_name,
  client_number,
  matter_number,
  created_timestamp,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const [params, containerRef] = useContainerQuery(query);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      activity_id,
      case_name,
      client_number,
      matter_number,
    },
  });

  const updateMutation = useUpdateCicero();
  const deleteMutation = useDeleteCicero();

  const goToItem = () => navigate(`/cicero/case/${id}`);

  const handleDelete = async () => {
    setProcessing(true);
    try {
      await deleteMutation.mutateAsync({ activity_id, context_id });
      setDialogOpen(false);
    } catch (error) {
      console.error("Error deleting case:", error);
    } finally {
      setProcessing(false);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setDialogOpen(true);
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      reset({
        case_name,
        client_number,
        matter_number,
      });
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        await updateMutation.mutateAsync({
          activity_id,
          ...values,
        });
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating case:", error);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const { isLoading, isError } = useQuery(ciceroOptions(id));

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  if (isError) {
    return <Typography variant="h6">Error loading data...</Typography>;
  }

  return (
    <Paper
      ref={containerRef}
      elevation={5}
      sx={{
        padding: 3,
        borderRadius: 3,
        height: "auto",
        minHeight: "300px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          alignItems="center"
          flexWrap={[
            "wrap",
            "wrap",
            "wrap",
            params["width-between-500-and-820"] ? "wrap" : "nowrap",
          ]}
          direction={["column", "row"]}
          gap={[2, 3]}
        >
          <Stack
            sx={{
              gap: 2,
              alignSelf: "stretch",
              justifyContent: "space-between",
              maxWidth: [
                "auto",
                "100%",
                "100%",
                params["width-between-500-and-820"] ? "100%" : 300,
              ],
              width: [
                "100%",
                "100%",
                "100%",
                params["width-between-500-and-820"] ? "100%" : 300,
              ],
            }}
          >
            <Stack sx={{ gap: 2 }}>
              <Typography variant="caption" as="h3" color="text.light">
                Created:&nbsp;
                {new Date(created_timestamp + "T00:00:00Z").toLocaleDateString(
                  "en-us",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    timeZone: "UTC",
                  }
                )}
              </Typography>
              <Typography
                variant="h4"
                as="h2"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: 2,
                }}
              >
                {activity_title}
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <Stack>
                <Typography color="text.light" variant="caption">
                  Case Name:
                </Typography>
                {isEditing ? (
                  <TextField
                    control={control}
                    placeholder="Case Name"
                    fullWidth
                    name="case_name"
                    trim={false}
                    size="small"
                  />
                ) : (
                  <Typography variant="body2" sx={{ minHeight: "0.5rem" }}>
                    {case_name}
                  </Typography>
                )}
              </Stack>
              <Stack>
                <Typography color="text.light" variant="caption">
                  Client Number:
                </Typography>
                {isEditing ? (
                  <TextField
                    control={control}
                    placeholder="Client Number"
                    fullWidth
                    name="client_number"
                    trim={false}
                    size="small"
                  />
                ) : (
                  <Typography variant="body2" sx={{ minHeight: "0.5rem" }}>
                    {client_number}
                  </Typography>
                )}
              </Stack>
              <Stack>
                <Typography color="text.light" variant="caption">
                  Matter Number:
                </Typography>
                {isEditing ? (
                  <TextField
                    control={control}
                    placeholder="Matter Number"
                    fullWidth
                    name="matter_number"
                    trim={false}
                    size="small"
                  />
                ) : (
                  <Typography variant="body2" sx={{ minHeight: "0.5rem" }}>
                    {matter_number}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Box flexGrow={1} />
            <Stack direction="row">
              <Tooltip title="Delete Recording" placement="bottom">
                <IconButton
                  onClick={openDeleteDialog}
                  cy-data="delete-case-btn"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={isEditing ? "Discard Changes" : "Edit Recording Name"}
                placement="bottom"
              >
                <IconButton onClick={toggleEdit}>
                  {isEditing ? <CancelIcon /> : <EditIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Open Recording" placement="bottom">
                <IconButton
                  onClick={goToItem}
                  cy-data="open-case-btn"
                  color="primary"
                >
                  <FolderIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Stack>
            {isEditing && (
              <Button
                size="small"
                variant="contained"
                endIcon={
                  loading ? <CircularProgress size={20} /> : <SaveIcon />
                }
                type="submit"
                disabled={loading}
                sx={{ mt: 2, mb: 0.5 }}
              >
                {loading ? "Saving..." : "Save Updates"}
              </Button>
            )}
          </Stack>
          {lgUp && !params["width-between-500-and-820"] && (
            <Divider orientation="vertical" flexItem />
          )}
          {/* content at the right side of the card will be added here later */}
        </Stack>
      </form>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="h2"
          sx={{ textAlign: "center" }}
        >
          Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Typography
              variant="h3"
              sx={{ fontWeight: 400, textAlign: "center" }}
              id="alert-dialog-description"
            >
              Are you sure you want to delete {title}?
            </Typography>
            <Typography variant="body1" color="text.light">
              Note: This action cannot be undone.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={processing}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            disabled={processing}
            cy-data="delete-agree-btn"
          >
            {processing ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              "Confirm Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default CiceroCard;

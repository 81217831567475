import { Box, CircularProgress, Stack, Tab, Tabs } from "@mui/material";
import { Suspense, useMemo } from "react";
import { sidebarOpenAtom, summaryTabs } from "../../../services/store";
import { useAtom, useSetAtom } from "jotai";
import { ErrorBoundary } from "react-error-boundary";
import { HTMLParser } from "../../components/HTMLParser";
import { JudgeCourtAnalysisSummary } from "../JudgeCourtSummary";
import { JudgeCourtQuotes } from "../JudgeCourtQuotes";
import { judgeCourtQuotesOptions } from "../../../services/queries";
import { useQueryClient } from "@tanstack/react-query";
import { useJudgeCourtData } from "../../../services/hooks/useJudgeCourtData";

export function JudgeCourtSummaryTabs({
  isJudge,
  judgeCourtId,
  briefSummary,
  isAggregate,
  isAggregateDoctrina,
  isCourtX,
  isJudgeX,
}) {
  const queryClient = useQueryClient();
  const setAsideOpen = useSetAtom(sidebarOpenAtom);
  const type = useMemo(() => (isJudge ? "judge" : "court"), [isJudge]);
  const [tab, setTab] = useAtom(summaryTabs(`${type}-${judgeCourtId}`));

  const { shouldHideQuotes } = useJudgeCourtData(judgeCourtId, type);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleClicks = {
    ReWrite: (event) => {
      event.preventDefault();
      setAsideOpen(true);
    },
    "Quotes Table": (event) => {
      event.preventDefault();
      setTab(briefSummary ? 2 : 1);
    },
  };

  const tabLabels = useMemo(() => {
    const labels = [];
    if (briefSummary) labels.push("Brief Summary");
    labels.push(isJudge ? "Judge Summary" : "Court Summary");
    if (!isCourtX && !isJudgeX && !shouldHideQuotes) {
      labels.push("Quotes");
    }
    return labels;
  }, [briefSummary, isJudge, isCourtX, isJudgeX, shouldHideQuotes]);

  queryClient.prefetchQuery(
    judgeCourtQuotesOptions({
      id: judgeCourtId,
      type: `${type}s`,
      page: 0,
      subject: [],
      category: [],
      judge: [],
    })
  );

  return (
    <Stack sx={{ gap: 4 }}>
      <Tabs
        value={tab}
        onChange={handleChange}
        sx={{
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: "grey.200",
        }}
      >
        {tabLabels.map((label, index) => (
          <Tab key={index} label={label} />
        ))}
      </Tabs>

      {briefSummary && (
        <Box hidden={tab !== 0}>
          <HTMLParser content={briefSummary} handleClicks={handleClicks} />
        </Box>
      )}

      <Box hidden={tab !== (briefSummary ? 1 : 0)}>
        <ErrorBoundary fallbackRender={() => "There was an error."}>
          <Suspense
            fallback={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="200px"
              >
                <CircularProgress size="2rem" />
              </Box>
            }
          >
            <JudgeCourtAnalysisSummary
              id={judgeCourtId}
              type={type}
              isJudgeX={isJudgeX}
              isCourtX={isCourtX}
            />
          </Suspense>
        </ErrorBoundary>
      </Box>

      {!shouldHideQuotes && (
        <Box hidden={tab !== (briefSummary ? 2 : 1)}>
          <Suspense
            fallback={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="200px"
              >
                <CircularProgress size="2rem" />
              </Box>
            }
          >
            <JudgeCourtQuotes
              id={judgeCourtId}
              type={`${type}s`}
              isAggregate={isAggregate}
              isAggregateDoctrina={isAggregateDoctrina}
            />
          </Suspense>
        </Box>
      )}
    </Stack>
  );
}

export default JudgeCourtSummaryTabs;

import { Box } from "@mui/material";
import { OnboardingTour } from "../common/OnboardingTour/OnboardingTour";

const ONBOARDING_STEPS = {
  1: {
    title: "Welcome to Rhetoric",
    description:
      "Your dashboard displays our suite of legal analytics tools. Each tool is designed to enhance different aspects of your legal practice.",
  },
  2: {
    title: "Navigation Menu",
    description:
      "The sidebar menu provides quick access to all Rhetoric tools from anywhere in the platform. Use these shortcuts to seamlessly switch between different analytics features.",
  },
  3: {
    title: "Video Tutorials",
    description:
      "Access context-specific video guides for each section of Rhetoric. This button is available throughout the entire platform, with each section having its own tailored video guide. Whether you're in Doctrina, DeBrief, or Judges, you'll find specific tutorial content designed for that feature.",
  },
  4: {
    title: "Help & Support",
    description:
      "Access our comprehensive help center with a single click. Find answers to common questions and get assistance whenever you need it.",
  },
  5: {
    title: "Support Resources",
    description:
      "Our help center offers multiple support channels: browse documentation, chat with our team, schedule training sessions, or reach out via email. Choose the option that best suits your needs.",
  },
};

const renderStep = ({
  step,
  highlightBox,
  commonHighlightStyles,
  isSmallScreen,
  StepPaper,
  StepArrow,
  ContentWrapper,
  steps,
  theme,
}) => {
  if (step === 1) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            bottom: 100,
            left: "50%",
            transform: "translateX(-50%)",
            flexDirection: "column",
            gap: isSmallScreen ? 2 : 3,
            maxWidth: "600px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          {!isSmallScreen && <StepArrow />}
          <StepPaper {...steps[1]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 2) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            top: highlightBox.top + highlightBox.height + 170,
            left: "13%",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: isSmallScreen ? 1 : 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: -10,
            }}
          >
            <StepArrow rotation={-45} />
          </Box>
          <StepPaper {...steps[2]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 3) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            top: isSmallScreen
              ? highlightBox.top + highlightBox.height - 350
              : highlightBox.top + highlightBox.height - 440,
            left: "20%",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: isSmallScreen ? 1 : 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: 30,
            }}
          >
            <StepArrow
              sx={{ marginTop: isSmallScreen ? "-30px" : 0 }}
              rotation={225}
            />
          </Box>
          <StepPaper {...steps[3]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 4) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            top: isSmallScreen
              ? highlightBox.top + highlightBox.height - 350
              : highlightBox.top + highlightBox.height - 440,
            left: "20%",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: isSmallScreen ? 1 : 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: 30,
            }}
          >
            <StepArrow
              sx={{ marginTop: isSmallScreen ? "-30px" : 0 }}
              rotation={225}
            />
          </Box>
          <StepPaper {...steps[4]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 5) {
    return (
      <>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "450px",
            height: "450px",
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
            boxShadow: `0 0 15px 1px ${theme.palette.primary.main}`,
            zIndex: 1301,
            backgroundColor: "transparent",
            pointerEvents: "none",
            backdropFilter: "brightness(1)",
          }}
        />
        <ContentWrapper
          sx={{
            top: "50%",
            left: "18%",
            transform: "translate(-50%, -50%)",
            flexDirection: "row",
            alignItems: "center",
            gap: isSmallScreen ? 2 : 5,
          }}
        >
          <StepPaper {...steps[5]} />
          {!isSmallScreen && (
            <Box
              sx={{
                marginTop: 0,
              }}
            >
              <StepArrow rotation={90} />
            </Box>
          )}
        </ContentWrapper>
      </>
    );
  }

  return null;
};

export const HomeOnboardingTour = ({
  onClose,
  currentStep = 1,
  onNext,
  onBack,
  highlightRef,
}) => {
  return (
    <OnboardingTour
      onClose={onClose}
      currentStep={currentStep}
      totalSteps={5}
      onNext={onNext}
      onBack={onBack}
      highlightRef={highlightRef}
      steps={ONBOARDING_STEPS}
      renderStep={renderStep}
    />
  );
};

import { useSuspenseQuery } from "@tanstack/react-query";
import * as api from "../api";
import { useLowSampleThreshold } from ".";
import { OPINION_COUNT_LIMIT } from "../models";

export function useJudgeCourtData(id, type) {
  const LowSampleThreshold = useLowSampleThreshold();

  const { data: entityData } = useSuspenseQuery({
    queryKey: [type, id],
    queryFn: async () => {
      if (type === "judge") {
        return await api.get(`/judges/${id}`);
      }
      return await api.get(`/courts/${id}`);
    },
  });

  const { data: tags } = useSuspenseQuery({
    queryKey: ["tags", id, type],
    queryFn: async () => await api.get(`/tags?${type}_id=${id}`),
  });

  const shouldHideQuotes =
    entityData?.court_opinions_count <
      entityData?.judges_count * LowSampleThreshold ||
    entityData?.judge_opinions_count < OPINION_COUNT_LIMIT;

  const shouldHideTags = (isCourtX, isJudgeX) =>
    (!isCourtX &&
      entityData?.court_opinions_count <
        entityData?.judges_count * LowSampleThreshold) ||
    (!isJudgeX && entityData?.judge_opinions_count < OPINION_COUNT_LIMIT);

  return {
    entityData,
    tags,
    shouldHideQuotes,
    shouldHideTags,
    LowSampleThreshold,
  };
}

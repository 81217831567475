import { Alert, Box } from "@mui/material";

export const BrowserWarning = () => {
  const isInternetExplorer = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return (
      userAgent.indexOf("msie") !== -1 || userAgent.indexOf("trident") !== -1
    );
  };

  if (!isInternetExplorer()) return null;

  return (
    <Box
      sx={{
        mt: "auto",
        pt: 2,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Alert
        severity="warning"
        sx={{
          boxShadow: 3,
          maxWidth: "800px",
          width: "100%",
          borderRadius: 1,
        }}
      >
        It appears you are using Internet Explorer, which is not supported by
        Rhetoric. To ensure an optimal experience using our platform, please use
        a modern browser such as Chrome, Safari, Edge or similar.
      </Alert>
    </Box>
  );
};

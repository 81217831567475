import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import InfoIcon from "@mui/icons-material/Info";
import { JudgesCardAsideAccordion } from "../../components/JudgesCardAside/JudgesCardAsideAccordion";
import { OPINION_COUNT_LIMIT } from "../../../services/models";
import { StatPieGraphText } from "../../components";
import { sidebarOpenAtom } from "../../../services/store";
import { useAtomValue } from "jotai";
import { useContainerQuery } from "react-container-query";
import { useMemo } from "react";

const query = {
  "width-between-0-and-300": {
    minWidth: 0,
    maxWidth: 300,
  },
};

const DeBriefCaseJudgeView = ({
  id,
  active = false,
  showPolarity = false,
  court,
  display_name,
  jurisprudence,
  tone,
  political_affiliation,
  date_of_birth,
  race,
  gender,
  judge_opinions_count,
  simple = false,
  type = "jurisprudence",
  sentiment,
}) => {
  const asideOpen = useAtomValue(sidebarOpenAtom);
  const [, containerRef] = useContainerQuery(query);

  const data = useMemo(() => {
    if (type === "jurisprudence") {
      return jurisprudence;
    }
    if (type === "sentiment") {
      return sentiment;
    }
    if (type === "tone") {
      return tone;
    }
  }, [type, jurisprudence, sentiment, tone]);

  return (
    <Box ref={containerRef}>
      <Stack
        backgroundColor={simple && active ? "transparent" : "white"}
        color={simple && active ? "white" : "text.main"}
        padding={3}
        spacing={3}
      >
        <Stack alignItems="center" flexDirection="row" gap={1}>
          <Stack spacing={1}>
            {id !== -1 && (
              <Typography variant="h3" as="h1">
                {display_name}
                {judge_opinions_count < OPINION_COUNT_LIMIT ? (
                  <Tooltip
                    sx={{ marginLeft: -1, marginTop: -1 }}
                    title="Limited Sample"
                  >
                    <IconButton>
                      <InfoIcon
                        color="primary"
                        sx={{ width: 15, height: 15 }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Typography>
            )}
            {court?.type &&
              court?.name &&
              (id !== -1 ? (
                <Typography
                  variant="body2"
                  as="h3"
                  color={simple && active ? "white" : "text"}
                >
                  Judge at {court.type}, {court.name}
                </Typography>
              ) : (
                <Typography
                  variant="h5"
                  as="h3"
                  color={simple && active ? "white" : "text"}
                >
                  {court.is_court_x
                    ? court.name
                    : `${court.type}, ${court.name}`}
                </Typography>
              ))}
          </Stack>
          <Tooltip
            sx={{ marginLeft: -1, marginTop: -1 }}
            title="Please note that judges often write opinions dealing with multifaceted legal issues. Accordingly, these scores that are specific to various subjects may derive from opinions that touch on numerous legal issues."
          >
            <IconButton>
              <InfoIcon color="primary" sx={{ width: 15, height: 15 }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Grid container spacing={asideOpen ? 3 : 2}>
          {Object.keys(data)?.map((key) => (
            <Grid xs={6} md={6} key={key}>
              <StatPieGraphText
                filled={true}
                showPolarity={showPolarity}
                label={key}
                value={data[key]}
                comparing={false}
                isCompare={true}
                isPrimary={true}
              />
            </Grid>
          ))}
        </Grid>
        {id !== -1 && (
          <Stack>
            <JudgesCardAsideAccordion heading="Personal Info">
              <Stack spacing={1}>
                {date_of_birth?.year && (
                  <Typography variant="body2">
                    Born: <strong>{date_of_birth.year}</strong>
                  </Typography>
                )}
                {race && (
                  <Typography variant="body2" textTransform="capitalize">
                    Race: <strong>{race}</strong>
                  </Typography>
                )}
                {gender && (
                  <Typography variant="body2" textTransform="capitalize">
                    Gender: <strong>{gender}</strong>
                  </Typography>
                )}
              </Stack>
            </JudgesCardAsideAccordion>
            {political_affiliation && (
              <JudgesCardAsideAccordion heading="Political Information">
                <Typography textTransform="capitalize" variant="body2">
                  {political_affiliation} (via appointer)
                </Typography>
              </JudgesCardAsideAccordion>
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export const DeBriefCaseJudge = ({
  simple = false,
  type = "jurisprudence",
  ...props
}) => (
  <Paper>
    <Box position="relative">
      <DeBriefCaseJudgeView filled aside={true} {...props} type={type} />
    </Box>
  </Paper>
);

import { JudgeCourtSummaryTabs } from "../../components/JudgeCourtSummaryTabs";
import { JudgesCard } from "../../components";
import { judgeOptions } from "../../../services/queries";
import { useSuspenseQuery } from "@tanstack/react-query";

export const JudgeContainer = ({ id }) => {
  const { data: judge } = useSuspenseQuery(judgeOptions(id));

  const isJudgeX = judge.is_judge_x;

  return (
    <>
      <JudgesCard id={id} isDetailPage />
      <JudgeCourtSummaryTabs isJudge judgeCourtId={id} isJudgeX={isJudgeX} />
    </>
  );
};

import { fetchAuthSession } from "aws-amplify/auth";
import { reportErrorToBackend } from "../errorLogging";

const base = async ({ path, options }) => {
  const session = await fetchAuthSession();
  const token = session.tokens.idToken.toString();
  const sessionId = session.tokens.accessToken.payload.event_id;

  try {
    const headers = {};

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
      headers["Content-Type"] = "application/json";
      headers["X-Rhetoric-Session-ID"] = sessionId;
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${path}`,
      {
        headers,
        ...options,
      }
    );

    if (response.status === 503) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (!window.location.pathname.includes("maintenance")) {
        window.location.href = "/maintenance";
      }
      throw new Error("MAINTENANCE_MODE");
    }

    let responseData;
    try {
      responseData = await response.clone().json();
    } catch (e) {
      responseData = null;
    }

    if (!response.ok && response.status !== 503) {
      await reportErrorToBackend(
        {
          status: response.status,
          statusText: response.statusText,
          endpoint: path,
          method: options.method,
          message:
            responseData?.message || `HTTP error! status: ${response.status}`,
          response:
            typeof responseData === "object"
              ? JSON.stringify(responseData)
              : String(responseData),
          name: "APIError",
          code: response.status,
        },
        "API"
      );
    }

    if (!response.ok) {
      throw new Error(
        responseData?.message || `HTTP error! status: ${response.status}`
      );
    }

    return responseData;
  } catch (error) {
    if (error.name !== "MAINTENANCE_MODE") {
      await reportErrorToBackend(
        {
          status: error.status,
          statusText: error.message,
          endpoint: path,
          method: options.method,
          message: error.message,
          response: error.toString(),
          name: error.name || "APIError",
          code: error.status,
        },
        "API"
      );
    }
    throw error;
  }
};

export const get = async (path, options = {}) => {
  return base({ path, options: { method: "GET", ...options } });
};

export const post = async (path, options = {}) => {
  return base({ path, options: { method: "POST", ...options } });
};

export const patch = async (path, options = {}) => {
  return base({ path, options: { method: "PATCH", ...options } });
};

export const deleted = async (path, options = {}) => {
  return base({ path, options: { method: "DELETE", ...options } });
};

export const file = async (url, options = {}) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      ...options,
    });

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return response;
  } catch (error) {
    throw error;
  }
};

import * as api from "../../../services/api";

import { Box, Button, CircularProgress, Stack } from "@mui/material";
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { atom, useAtom } from "jotai";

import { DeBriefEmpty } from "./DeBriefEmpty";
import { DeBriefItems } from "./DeBriefItems";
import { Default } from "../../layouts";
import { Error } from "../../views/Error";
import Grid from "@mui/material/Unstable_Grid2";
import { useInfiniteQuery } from "@tanstack/react-query";
import { DebriefOnboardingTour } from "../../components";
import { debriefOnboardingState } from "../../../services/store";

const selectedAtom = atom(-1);

const DeBriefView = () => {
  const [debriefOnboarding, setDebriefOnboarding] = useAtom(
    debriefOnboardingState
  );
  const mainContentRef = useRef(null);
  const [refs, setRefs] = useState({
    newCaseButtonRef: null,
    firstCardRef: null,
    openCaseButtonRef: null,
    actionButtonsRef: null,
    emptyAreaRef: null,
  });

  const { data } = useInfiniteQuery({
    queryKey: ["briefs"],
    queryFn: async ({ pageParam = 1 }) =>
      await api.get(`/v3/briefs?page=${pageParam}`),
    getNextPageParam: (lastPage, pages) => {
      return lastPage.num_pages === pages.length ? undefined : pages.length + 1;
    },
    throwOnError: true,
  });

  const highlightRef = useMemo(() => {
    if (!debriefOnboarding.isActive) return null;

    const items = data?.pages?.map((data) => data.briefs).flat() || [];
    const hasItems = items.length > 0;

    switch (debriefOnboarding.currentStep) {
      case 1:
        return mainContentRef;
      case 2:
        return refs.newCaseButtonRef;
      case 3:
        return hasItems ? refs.firstCardRef : refs.emptyAreaRef;
      case 4:
        return hasItems ? refs.openCaseButtonRef : null;
      case 5:
        return hasItems ? refs.actionButtonsRef : null;
      default:
        return null;
    }
  }, [debriefOnboarding.isActive, debriefOnboarding.currentStep, refs, data]);

  const handleCloseOnboarding = () => {
    setDebriefOnboarding({
      isActive: false,
      currentStep: 1,
    });
  };

  const handleNextStep = () => {
    const items = data?.pages?.map((data) => data.briefs).flat() || [];
    const hasItems = items.length > 0;
    const lastStep = hasItems ? 5 : 3;

    if (debriefOnboarding.currentStep === lastStep) {
      handleCloseOnboarding();
    } else {
      setDebriefOnboarding((prev) => ({
        ...prev,
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  const handleBackStep = () => {
    setDebriefOnboarding((prev) => ({
      ...prev,
      currentStep: prev.currentStep - 1,
    }));
  };

  const items = data?.pages?.map((data) => data.briefs).flat() || [];
  const hasItems = items.length > 0;
  const totalSteps = hasItems ? 5 : 3;

  return (
    <Default>
      {debriefOnboarding.isActive && (
        <DebriefOnboardingTour
          onClose={handleCloseOnboarding}
          currentStep={debriefOnboarding.currentStep}
          totalSteps={totalSteps}
          onNext={handleNextStep}
          onBack={handleBackStep}
          highlightRef={highlightRef}
          items={items}
        />
      )}
      <Error>
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress size="3rem" />
            </Box>
          }
        >
          <Fetch mainContentRef={mainContentRef} onRefsReady={setRefs} />
        </Suspense>
      </Error>
    </Default>
  );
};

function Fetch({ mainContentRef, onRefsReady }) {
  const [selected, setSelected] = useAtom(selectedAtom);
  const [page, setPage] = useState(1);

  const handleSelect = useCallback(
    (value) => {
      setSelected(value);
    },
    [setSelected]
  );

  const handleRefsReady = useCallback(
    (newRefs) => {
      if (onRefsReady) {
        onRefsReady(newRefs);
      }
    },
    [onRefsReady]
  );

  const {
    isLoading,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: ["briefs"],
    queryFn: async ({ pageParam = 1 }) =>
      await api.get(`/v3/briefs?page=${pageParam}`),
    getNextPageParam: (lastPage) => {
      return lastPage.num_pages === page ? undefined : page + 1;
    },
    throwOnError: true,
  });

  const handleFetchMore = useCallback(() => {
    const next = page + 1;
    setPage(next);
    fetchNextPage();
  }, [fetchNextPage, page]);

  useEffect(() => {
    if (isRefetching) {
      setPage(1);
    }
  }, [isRefetching]);

  const items = useMemo(() => {
    return data?.pages?.map((data) => data.briefs).flat() || [];
  }, [data]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  return (
    <>
      <Box ref={mainContentRef}>
        {items.length === 0 && (
          <Box padding={4}>
            <Grid container spacing={2}>
              <DeBriefEmpty onRefsReady={handleRefsReady} />
            </Grid>
          </Box>
        )}
        {items && items?.length !== 0 && (
          <DeBriefItems
            selected={selected}
            setSelected={handleSelect}
            items={items}
            onRefsReady={handleRefsReady}
          />
        )}
      </Box>

      {(isFetchingNextPage || hasNextPage) && items && items?.length !== 0 && (
        <Stack sx={{ py: 4 }}>
          <Button onClick={handleFetchMore} variant="contained">
            {isFetchingNextPage ? (
              <CircularProgress color="inherit" size="3rem" />
            ) : (
              "Load More"
            )}
          </Button>
        </Stack>
      )}
    </>
  );
}

export const DeBrief = () => <DeBriefView />;

import * as api from "../api";
import { useMutation } from "@tanstack/react-query";
import { useCompletedAssignments } from "../../services/queries";

export const useUpdateCicero = () => {
  const { refetch } = useCompletedAssignments();

  const mutation = useMutation({
    mutationFn: (payload) =>
      api.patch(`/cicero`, { body: JSON.stringify(payload) }),
    onSuccess: () => {
      refetch();
    },
  });

  return mutation;
};

export const useDeleteCicero = () => {
  const { refetch } = useCompletedAssignments();

  const mutation = useMutation({
    mutationFn: ({ activity_id, context_id }) =>
      api.deleted(`/cicero/${context_id}/${activity_id}`),
    onSuccess: () => {
      refetch();
    },
  });

  return mutation;
};

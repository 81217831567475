import * as api from "../api";

import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { fetchAuthSession } from "aws-amplify/auth";

export function userOptions() {
  return queryOptions({
    queryKey: ["user"],
    queryFn: async () => {
      const { tokens } = await fetchAuthSession({ forceRefresh: true });
      const { idToken } = tokens;
      const { payload } = idToken;

      return {
        email: payload.email,
        username: payload.username,
        user_agreement_signature_needed:
          payload?.user_agreement_signature_needed === "true",
        user_registration_needed: payload?.user_registration_needed === "true",
        intercom_hash: payload?.intercom_hash,
      };
    },
    refetchOnWindowFocus: true,
  });
}

export function doctrinaNamesOptions() {
  return queryOptions({
    queryKey: ["doctrina", "names"],
    queryFn: async () => await api.get(`/courts/names`),
  });
}

export function listSubjectsOptions(type, id) {
  return queryOptions({
    queryKey: ["subjects", type, id],
    queryFn: async () => await api.get(`/briefs/${type}-subjects/${id}`),
  });
}

export function listCategoriesOptions(type, id) {
  return queryOptions({
    queryKey: ["categories", type, id],
    queryFn: async () => await api.get(`/briefs/${type}-categories/${id}`),
  });
}

export function briefOptions(id) {
  return queryOptions({
    queryKey: ["brief", id],
    queryFn: async () => await api.get(`/briefs/${id}`),
  });
}

export function judgeOptions(id, params) {
  return queryOptions({
    queryKey: ["judge", id, params],
    queryFn: async () =>
      await api.get(`/judges/${id}${params?.length ? `?${params}` : ""}`),
  });
}

export function doctrinaOptions(id, params) {
  return queryOptions({
    queryKey: ["court", id, params],
    queryFn: async () =>
      await api.get(`/courts/${id}${params?.length ? `?${params}` : ""}`),
  });
}

export function useCreateBrief() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["brief"],
    mutationFn: async ({ files, ...rest }) => {
      const file = files[0];
      const { name: filename } = file;

      const { url, fields, brief_id } = await api.post("/briefs", {
        body: JSON.stringify({
          ...rest,
          filename,
        }),
      });

      const formData = new FormData();

      Object.entries(fields).forEach((entry) => {
        const [key, value] = entry;
        formData.append(key, value);
      });

      formData.append("file", file);

      await api.file(url, { method: "POST", body: formData });

      let response;
      const maxRetries = 5;
      const retryDelay = 2000;

      for (let attempt = 0; attempt < maxRetries; attempt++) {
        response = await api.get(`/briefs/${brief_id}`);
        if (response.status !== "PENDING") {
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }

      return { brief_id, case: response };
    },
    onSuccess: ({ brief_id }) => {
      queryClient.invalidateQueries({ queryKey: ["briefs"] });
      queryClient.refetchQueries({ queryKey: ["briefs"] });
    },
  });
}

export function ciceroOptions() {
  return queryOptions({
    queryKey: ["cicero"],
    queryFn: async () => await api.get(`/cicero/get_completed_assignments`),
  });
}

export function completedAssignmentsOptions(options = {}) {
  return queryOptions({
    queryKey: ["completedAssignments"],
    queryFn: async () => await api.get("/cicero/get_completed_assignments"),
    ...options,
  });
}

export function useCompletedAssignments(options = {}) {
  return useQuery(completedAssignmentsOptions(options));
}

export function judgeCourtQuotesOptions({
  id,
  type,
  page,
  subject,
  category,
  judge,
}) {
  return queryOptions({
    queryKey: [id, type, page, subject, category, judge],
    queryFn: async () => {
      const params = {};
      if (page !== 0) {
        params.page = page;
      }

      if (subject.length !== 0) {
        params.subject = subject?.map((item) => item.id).join(",");
      }

      if (category.length !== 0) {
        params.category = category?.map((item) => item.id).join(",");
      }

      if (judge.length !== 0) {
        params.judge_id = judge?.map((item) => item.id).join(",");
      }

      return await api.get(
        `/${type}/${id}/quotes${
          params.length !== 0
            ? `?${decodeURIComponent(new URLSearchParams(params).toString())}`
            : ""
        }`
      );
    },
  });
}

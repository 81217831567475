import {
  Divider,
  Stack,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { StatPieGraphText } from "../StatPieGraphText";
import InfoIcon from "@mui/icons-material/Info";

export const DoctrinaCardAside = ({
  active = false,
  showPolarity = false,
  donutData,
  donutDeltas,
  politicalAffiliationDeltas,
  name,
  type,
  judges_count,
  court_opinions_count,
  political_affiliations,
  isCourtX = false,
  isCompare = false,
  isPrimary = false,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Stack
        padding={3}
        spacing={3}
        backgroundColor="white"
        color="text.main"
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Stack spacing={1}>
          <Typography variant="h3">{name}</Typography>
          <Typography variant="h4">{type}</Typography>
        </Stack>
        <Stack direction={isSmallScreen ? "column" : "row"} spacing={1}>
          <Typography>{judges_count} Judges</Typography>
          <Typography>{court_opinions_count} Opinions</Typography>
        </Stack>
        <Box
          sx={{
            position: "absolute",
            top: isSmallScreen ? "50%" : "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
          }}
        >
          <Grid container rowSpacing={1.5}>
            {donutData &&
              Object.keys(donutData)?.length > 0 &&
              Object.keys(donutData)?.map((key) => (
                <Grid xs={6} key={key}>
                  <StatPieGraphText
                    filled={active ? false : true}
                    showPolarity={showPolarity}
                    label={key}
                    active={active}
                    value={donutData[key]}
                    delta={donutDeltas[key]}
                    comparing={active}
                    isCompare={isCompare}
                    isPrimary={isPrimary}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: isSmallScreen ? "80%" : "72%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
          }}
        >
          <Divider sx={{ marginTop: 8 }} />
          <Typography sx={{ marginTop: 3 }}>Court Demographics</Typography>
          {isCourtX ? (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ marginTop: 2 }}
            >
              <InfoIcon color="action" fontSize="small" />
              <Typography variant="body2">
                Please note that court demographics are not available for Court
                X
              </Typography>
            </Stack>
          ) : (
            <Grid container rowSpacing={1.5} sx={{ marginTop: 3 }}>
              {Object.keys(political_affiliations).map((key) => (
                <Grid xs={6} key={key}>
                  <StatPieGraphText
                    comparing={active}
                    label={key}
                    value={political_affiliations[key]}
                    delta={politicalAffiliationDeltas[key]}
                    filled={active ? false : true}
                    showPolarity={showPolarity}
                    active={active}
                    isCompare={isCompare}
                    isPrimary={isPrimary}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

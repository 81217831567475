import React from "react";
import {
  Button,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Paper,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@emotion/react";

export const getTagColor = (theme, level) => {
  const baseColor = theme.palette.primary.main;
  const opacity = 0.3 + (level - 1) * 0.2;
  return `${baseColor}${Math.round(opacity * 255)
    .toString(16)
    .padStart(2, "0")}`;
};

export function handleHighlightText(id, color) {
  const element = document.getElementById(id.replace(" ", "-").toLowerCase());
  if (element) element.style.backgroundColor = `${color}25`;
}

export function handleUnHighlightText(id) {
  const element = document.getElementById(id.replace(" ", "-").toLowerCase());
  if (element) element.style.backgroundColor = `transparent`;
}

const multiTagStyle = {
  borderRadius: 5,
  px: 2,
  py: 0.5,
  backgroundColor: "white",
  color: "#4B0082",
  border: "2px solid #4B0082",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      linear-gradient(
        45deg,
        rgba(75, 0, 130, 0.05) 25%,
        transparent 25%,
        transparent 50%,
        rgba(75, 0, 130, 0.05) 50%,
        rgba(75, 0, 130, 0.05) 75%,
        transparent 75%,
        transparent 100%
      )
    `,
    backgroundSize: "10px 10px",
    zIndex: 0,
  },
  "& > span": {
    position: "relative",
    zIndex: 1,
  },
  ":hover": {
    backgroundColor: "rgba(75, 0, 130, 0.1)",
  },
};

const simpleTagStyle = (theme, level) => ({
  backgroundColor: getTagColor(theme, level),
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: getTagColor(theme, level + 1),
  },
});

export const TagSection = ({ title, tags, isMulti }) => {
  const theme = useTheme();

  const tooltipText = isMulti
    ? "Combination Tags are special tags that represent a rare combination of two or more basic tags. These tags are less common than basic tags and indicate a more significant divergence from the average court or judge."
    : "Basic Tags are descriptors that identify a Judge or Court's particular usage of a Subcategory in comparison to the average court or judge. These tags have four 'levels' (Slightly, Moderately, Significantly, and Extremely) that represent further divergence from the average court or judge.";

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={0.5} sx={{ mb: 2 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, color: theme.palette.text.primary }}
        >
          {title}
        </Typography>
        <Tooltip placement="right" title={tooltipText}>
          <IconButton
            size="small"
            color="primary"
            sx={{
              p: 0,
              ml: 0.5,
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <InfoIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack sx={{ flexDirection: "row", flexWrap: "wrap", gap: 1.5 }}>
        {tags.map((tag) => (
          <Tooltip key={tag.tag_text} title={tag.tag_hover}>
            <Button
              size="small"
              variant="contained"
              sx={
                isMulti ? multiTagStyle : simpleTagStyle(theme, tag.tag_level)
              }
              onMouseEnter={() => handleHighlightText(tag.tag_text, "#4B0082")}
              onMouseLeave={() => handleUnHighlightText(tag.tag_text)}
            >
              {tag.tag_text}
            </Button>
          </Tooltip>
        ))}
      </Stack>
    </Paper>
  );
};

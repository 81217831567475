import { Box, CircularProgress, Stack } from "@mui/material";

import { Default } from "../../layouts";
import { JudgeContainer } from "./JudgeContainer";
import { JudgeFilter } from "./JudgeFilter";
import { Suspense } from "react";
import { memo } from "react";
import { useParams } from "react-router-dom";

const JudgeView = memo(() => {
  const { id } = useParams();

  return (
    <Default>
      <Stack sx={{ p: [0, 4], gap: 2 }}>
        <JudgeFilter id={id} />
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <CircularProgress size="3rem" />
            </Box>
          }
        >
          <JudgeContainer id={id} />
        </Suspense>
      </Stack>
    </Default>
  );
});

export const Judge = () => <JudgeView />;

export default Judge;

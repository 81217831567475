const VIDEO_CONTENT = {
  home: {
    path: "/",
    title: "How to Use Rhetoric",
    videoUrl: "https://www.youtube.com/embed/YhI3QXfkvrE",
  },
  doctrina: {
    path: "/doctrina",
    title: "How to Use Doctrina",
    videoUrl: "https://www.youtube.com/embed/qR5srqzdZ5k",
  },
  doctrinaCompare: {
    path: "/doctrina/compare",
    title: "How to Compare Courts",
    videoUrl: "https://www.youtube.com/embed/AwZ-s7hypIg",
  },
  doctrinaDetail: {
    pathRegex: /^\/doctrina\/(\d+)$/,
    title: "How to Use Detailed View",
    videoUrl: "https://www.youtube.com/embed/sr9zXE-naGs",
  },
  judges: {
    path: "/judges",
    title: "How to Use Judges",
    videoUrl: "https://www.youtube.com/embed/51ubgJ1HdiU",
  },
  judgesCompare: {
    path: "/judges/compare",
    title: "How to Compare Judges",
    videoUrl: "https://www.youtube.com/embed/qn56cESvamQ",
  },
  judgesDetail: {
    pathRegex: /^\/judges\/(\d+)$/,
    title: "How to Use Detailed View",
    videoUrl: "https://www.youtube.com/embed/GiAlfCBGi-Q",
  },
  debrief: {
    path: "/debrief",
    title: "How to Use Debrief",
    videoUrl: "https://www.youtube.com/embed/oLgutwPYhM0",
  },
  debriefCreate: {
    path: "/debrief/create",
    title: "How to Upload a Case",
    videoUrl: "https://www.youtube.com/embed/PV6nCRRK7Es",
  },
  debriefCase: {
    pathRegex: /^\/debrief\/case\/(\d+)$/,
    title: "How to Use DeBrief Analysis",
    videoUrl: "https://www.youtube.com/embed/Z31wP9eX8z0",
  },
  cicero: {
    path: "/cicero",
    title: "How to Use Cicero",
    videoUrl: "https://www.youtube.com/embed/LyRbE5XR12o",
  },
  rewrite: {
    standalone: true,
    title: "How to Use ReWrite",
    videoUrl: "https://www.youtube.com/embed/CxDxQE7E-ug",
  },
  contextCardPositive: {
    standalone: true,
    title: "How to Use Positive Delta Cards",
    videoUrl: "https://www.youtube.com/embed/n6btDPQhss0",
  },
  contextCardNegative: {
    standalone: true,
    title: "How to Use Negative Delta Cards",
    videoUrl: "https://www.youtube.com/embed/j-HvuUj7x3E",
  },
};

export const getVideoContent = (pathname) => {
  const exactMatch = Object.values(VIDEO_CONTENT).find(
    (content) => content.path === pathname
  );
  if (exactMatch) return exactMatch;

  const regexMatch = Object.values(VIDEO_CONTENT).find(
    (content) => content.pathRegex && content.pathRegex.test(pathname)
  );
  if (regexMatch) return regexMatch;

  return null;
};

export const getStandaloneVideo = (key) => {
  const video = VIDEO_CONTENT[key];
  return video?.standalone ? video : null;
};

export const getContextCardVideo = (delta) => {
  return delta >= 0
    ? VIDEO_CONTENT.contextCardPositive
    : VIDEO_CONTENT.contextCardNegative;
};

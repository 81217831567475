import React, { useRef, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link as RouterLink } from "react-router-dom";

export const DeBriefEmpty = ({ onRefsReady }) => {
  const newCaseButtonRef = useRef(null);
  const emptyAreaRef = useRef(null);

  useEffect(() => {
    if (onRefsReady) {
      onRefsReady({
        newCaseButtonRef,
        firstCardRef: null,
        emptyAreaRef,
        openCaseButtonRef: null,
        actionButtonsRef: null,
      });
    }
  }, [onRefsReady]);

  return (
    <Grid xs={12}>
      <Stack>
        <Box
          backgroundColor="white"
          paddingY={2}
          paddingX={[0, 4]}
          position="sticky"
          top={0}
          zIndex={10}
        >
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                marginTop={-2}
                marginBottom={2}
              >
                <Button
                  ref={newCaseButtonRef}
                  component={RouterLink}
                  to="/debrief/create"
                  variant="contained"
                  color="primary"
                >
                  New Case
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box backgroundColor="grey.100" paddingY={4} paddingX={[0, 4]}>
          <Stack
            ref={emptyAreaRef}
            border="2px dashed grey"
            borderColor="grey.500"
            borderRadius={3}
            height={400}
            justifyContent="center"
            alignItems="center"
            padding={4}
            width="100%"
          >
            <Typography variant="h3" color="text.secondary" align="center">
              Start your first case
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Grid>
  );
};

import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import {
  jurisprudence,
  sentiment,
  tone,
} from "../../../services/store/definitions";
import { useCallback, useMemo } from "react";

import { ResponsivePie } from "@nivo/pie";

const definitions = { ...jurisprudence, ...sentiment, ...tone };

export const StatPieGraphText = ({
  color = "",
  active = false,
  label = "",
  value,
  delta,
  simple,
  thickness = 0.9,
  dimension = 75,
  typeVariant = "p",
  isCompare = false,
  overrideColor,
  isPrimary = false,
}) => {
  const theme = useTheme();

  const graphColor = isCompare
    ? isPrimary
      ? theme.palette.primary.main
      : theme.palette.grey[300]
    : overrideColor || { datum: "data.color" };
  const textColor = theme.typography.body1.main;

  const getColorKey = useCallback(
    (value) => {
      if (color) return color;
      if (simple && active) return "white";
      if (Math.abs(+value) < 10) return theme.palette.success.main;
      if (Math.abs(+value) < 20) return theme.palette.warning.main;
      return theme.palette.error.main;
    },
    [
      color,
      active,
      simple,
      theme.palette.success.main,
      theme.palette.warning.main,
      theme.palette.error.main,
    ]
  );

  const data = useMemo(() => {
    if (isCompare) {
      return [{ id: 1, value: 100, color: graphColor }];
    } else {
      return [
        { id: 1, value: value, color: getColorKey(value) },
        { id: 2, value: 100 - value, color: theme.palette.grey[300] },
      ];
    }
  }, [graphColor, value, isCompare, theme.palette.grey, getColorKey]);

  return (
    <Tooltip
      title={definitions[label.toLowerCase()] || ""}
      placement="top"
      arrow
    >
      <Stack alignItems="center" gap={1}>
        <Box height={dimension} width={dimension} position="relative">
          <ResponsivePie
            isInteractive={false}
            enableArcLinkLabels={false}
            enableArcLabels={false}
            data={data}
            innerRadius={thickness}
            padAngle={0}
            colors={isCompare ? graphColor : { datum: "data.color" }}
            fit
          />
          <Typography
            position="absolute"
            top="50%"
            left="50%"
            variant={typeVariant}
            sx={{
              transform: "translate3d(-50%, -50%, 0)",
              textAlign: "center",
            }}
            color={textColor}
          >
            {value !== undefined && value !== null ? value : "0"}
          </Typography>
          {delta !== undefined && !active && (
            <Box
              position="absolute"
              top={-10}
              right={-10}
              width={40}
              height={40}
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: getColorKey(delta),
                color: "white",
                fontSize: "0.9rem",
                fontWeight: "bold",
                zIndex: 1,
                border: `4px solid ${theme.palette.background.paper}`,
              }}
            >
              {delta > 0 ? `+${delta}` : delta}
            </Box>
          )}
        </Box>

        <Typography variant="caption" textTransform="capitalize">
          {label}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

import * as api from "../../../services/api";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Modal,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
  useTheme,
  Tooltip,
  alpha,
} from "@mui/material";
import {
  CheckCircleOutline,
  Close,
  Link as LinkIcon,
} from "@mui/icons-material";
import {
  QueryErrorResetBoundary,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { Suspense, useEffect, useMemo, useState, useRef } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ErrorBoundary } from "react-error-boundary";
import Grid from "@mui/material/Unstable_Grid2";
import { QuoteVote, StatPieGraphText } from "../../components";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useCopyToClipboard } from "../../../services/hooks/useCopyToClipboard";
import { PlayCircleOutline as VideoIcon } from "@mui/icons-material";
import { VideoModal } from "../../components/VideoModal/VideoModal";
import { getContextCardVideo } from "../../../services/videos";
import { useAtom } from "jotai";
import { contextCardModalAtom } from "../../../services/store";

const useColor = (value) => {
  const theme = useTheme();

  if (Math.abs(+value) < 10) return theme.palette.success.main;
  if (Math.abs(+value) < 20) return theme.palette.warning.main;
  return theme.palette.error.main;
};

export const DeBriefCaseContextCard = (props) => {
  const [modalState, setModalState] = useAtom(contextCardModalAtom);
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const handleOpenVideo = () => setVideoModalOpen(true);
  const handleCloseVideo = () => setVideoModalOpen(false);

  const videoContent = getContextCardVideo(props.delta);

  const handleOpenModal = () => {
    setModalState({
      isOpen: true,
      cardProps: props,
    });
  };

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      cardProps: null,
    });
  };

  return (
    <>
      <Modal
        open={
          modalState.isOpen &&
          modalState.cardProps?.measure === props.measure &&
          modalState.cardProps?.delta === props.delta
        }
        onClose={handleCloseModal}
        sx={{
          bgcolor: "rgba(0, 0, 0, 0.3)",
          backdropFilter: "blur(4px)",
        }}
      >
        <Stack justifyContent="center" alignItems="center" height="100vh">
          <Box
            maxWidth={1100}
            width="80%"
            sx={{
              bgcolor: "background.paper",
              borderRadius: 1,
              maxHeight: "90vh",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                zIndex: 1,
              }}
            >
              <IconButton size="small" onClick={handleCloseModal}>
                <Close sx={{ width: 25, height: 25 }} />
              </IconButton>
            </Box>

            <DeBriefCaseContextCardBox
              modal
              onOpenVideo={handleOpenVideo}
              setModalOpen={handleCloseModal}
              modalScroll
              {...props}
            />
          </Box>
        </Stack>
      </Modal>

      <VideoModal
        open={videoModalOpen}
        onClose={handleCloseVideo}
        title={videoContent.title}
        videoUrl={videoContent.videoUrl}
      />

      <DeBriefCaseContextCardBox
        onOpenVideo={handleOpenVideo}
        setModalOpen={handleOpenModal}
        {...props}
      />
    </>
  );
};

export const DeBriefCaseContextCardBoxLoader = () => {
  return (
    <Paper
      sx={{
        position: "relative",
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
        borderLeftColor: "neutral",
      }}
    >
      <Grid container spacing={0}>
        <Grid sm={12} md={4}>
          <Stack height="100%" backgroundColor="grey.200" padding={4} gap={3}>
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography variant="h4">
                <Skeleton width={100} />
              </Typography>
              <Typography variant="h4" fontWeight={300} color="grey.600">
                <Skeleton width={30} />
              </Typography>
            </Stack>
            <Typography variant="body2">
              <Skeleton />
            </Typography>
          </Stack>
        </Grid>
        <Grid sm={12} md={8}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            minHeight="200px"
          >
            <CircularProgress color="secondary" size={15} />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const DeBriefCaseContextCardBox = ({
  modal = false,
  measure,
  heading,
  delta,
  quotes,
  brief_id,
  brief_version_id,
  isJudge,
  versions,
  versionIndex,
  type,
  onOpenVideo,
  setModalOpen,
  modalScroll,
}) => {
  const theme = useTheme();
  const color = useColor(delta);
  const [highlightedType, setHighlightedType] = useState(null);

  const types = useMemo(() => {
    const t = quotes?.map((quote) => quote.type) ?? [];
    return [
      ...new Set(
        t.filter(
          (item) =>
            item !== undefined && !["j", "s", "t"].includes(item.toLowerCase())
        )
      ),
    ];
  }, [quotes]);

  const getStatusTitle = (color) => {
    switch (color) {
      case theme.palette.error.main:
        return "High Priority";
      case theme.palette.warning.main:
        return "Priority";
      case theme.palette.success.main:
        return "Good Job!";
      default:
        return "";
    }
  };

  const statusTitle = getStatusTitle(color);

  const quoteCount = quotes?.length || 0;

  const handleTypeHover = (type, isHovering) => {
    setHighlightedType(isHovering ? type : null);
  };

  const videoContent = getContextCardVideo(delta);

  return (
    <Paper
      sx={{
        position: "relative",
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
        borderLeftColor: color,
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{ height: modalScroll ? "90vh" : "auto" }}
      >
        <Grid
          sm={12}
          md={4}
          sx={{
            display: modal ? ["none", "none", "block"] : null,
            height: modalScroll ? "100%" : "auto",
          }}
        >
          <Stack
            height="100%"
            backgroundColor="grey.200"
            padding={4}
            gap={2}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box display="flex" justifyContent="center" width="100%" mb={1}>
              <Typography
                variant="overline"
                color="grey.800"
                fontWeight="bold"
                sx={{
                  display: "inline-block",
                  backgroundColor: alpha(color, 0.1),
                  padding: "2px 8px",
                  borderRadius: "4px",
                  border: `1px solid ${color}`,
                }}
              >
                {statusTitle}
              </Typography>
            </Box>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">{measure}</Typography>
              <StatPieGraphText
                value={
                  versions?.[versionIndex]?.[type]?.[measure.toLowerCase()]
                }
                delta={delta}
                label=""
                comparing={false}
                filled={true}
                showPolarity={false}
                isCompare={true}
                size={60}
              />
            </Stack>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            <Button
              startIcon={<VideoIcon />}
              size="small"
              variant="outlined"
              color="primary"
              onClick={onOpenVideo}
              sx={{ mt: "auto" }}
            >
              {videoContent.title}
            </Button>
          </Stack>
        </Grid>
        <Grid
          sm={12}
          md={8}
          sx={{
            height: modalScroll ? "100%" : "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              padding: 4,
              pt: modalScroll ? 6 : 4,
            }}
          >
            <Typography variant="h4" textAlign="center">
              {delta > 0
                ? `Document Quote${quoteCount !== 1 ? "s" : ""}`
                : isJudge
                ? `Judge Quote${quoteCount !== 1 ? "s" : ""}`
                : `Court Quote${quoteCount !== 1 ? "s" : ""}`}
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1,
              overflow: modalScroll ? "auto" : "visible",
              px: 4,
              pb: 4,
            }}
          >
            {Math.abs(delta) < 10 && (
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CheckCircleOutline
                  color="success"
                  sx={{ width: 120, height: 120 }}
                />
              </Stack>
            )}
            {Math.abs(delta) >= 10 && (
              <Stack gap={2}>
                {types.length !== 0 &&
                  types.slice(0, modal ? quotes?.length : 3).map((type) => {
                    const qts = quotes.filter((quote) => quote.type === type);
                    return (
                      <Stack
                        key={`quote-context-${type}-${measure}-${delta}`}
                        sx={{ gap: 1 }}
                      >
                        <Typography
                          variant="h4"
                          sx={(theme) => ({
                            textTransform: "capitalize",
                            cursor: "pointer",
                            color: theme.palette.primary.main,
                            display: "inline-block",
                            transition: "text-shadow 0.3s",
                            "&:hover": {
                              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                            },
                          })}
                          onMouseEnter={() => handleTypeHover(type, true)}
                          onMouseLeave={() => handleTypeHover(type, false)}
                        >
                          {type}
                        </Typography>
                        {qts
                          ?.slice(0, modal ? quotes?.length : 3)
                          ?.map(
                            (
                              {
                                quote,
                                explanation,
                                opinion_url,
                                quote_id,
                                quote_vote,
                                type,
                              },
                              index
                            ) => (
                              <DeBriefCaseContextItem
                                key={`quote-context-${index}-${measure}-${delta}`}
                                quote={quote ?? ""}
                                explanation={explanation ?? ""}
                                opinion_url={opinion_url ?? ""}
                                quote_id={quote_id}
                                quote_vote={quote_vote}
                                quote_type={type}
                                isHighlighted={highlightedType === type}
                              />
                            )
                          )}
                      </Stack>
                    );
                  })}
                {types.length === 0 &&
                  quotes
                    ?.slice(0, modal ? quotes?.length : 5)
                    ?.map(
                      (
                        {
                          quote,
                          explanation,
                          opinion_url,
                          quote_id,
                          quote_vote,
                          type,
                        },
                        index
                      ) => (
                        <DeBriefCaseContextItem
                          key={`quote-context-${index}-${measure}-${delta}`}
                          quote={quote ?? ""}
                          explanation={explanation ?? ""}
                          opinion_url={opinion_url ?? ""}
                          quote_id={quote_id}
                          quote_vote={quote_vote}
                          quote_type={type}
                        />
                      )
                    )}
                {types?.length === 0 && quotes?.length === 0 && (
                  <QueryErrorResetBoundary>
                    {({ reset }) => (
                      <ErrorBoundary
                        onReset={reset}
                        fallbackRender={({ resetErrorBoundary }) => (
                          <Stack sx={{ gap: 1 }}>
                            <Typography variant="body2">
                              There was an error loading these quotes.
                            </Typography>
                            <Button
                              size="small"
                              variant="contained"
                              color="tertiary"
                              onClick={() => resetErrorBoundary()}
                            >
                              Try again
                            </Button>
                          </Stack>
                        )}
                      >
                        <Suspense
                          fallback={
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              minHeight="100px"
                            >
                              <CircularProgress size="1.25rem" />
                            </Box>
                          }
                        >
                          <ExtraQuotes
                            brief_id={brief_id}
                            brief_version_id={brief_version_id}
                            delta={delta}
                            measure={measure}
                            cutoff={3 - quotes?.length || 0}
                            modal={modal}
                          />
                        </Suspense>
                      </ErrorBoundary>
                    )}
                  </QueryErrorResetBoundary>
                )}
              </Stack>
            )}
          </Box>
          {!modal &&
            !!quotes &&
            Math.abs(delta) >= 10 &&
            (() => {
              const totalQuotes =
                types.length === 0
                  ? quotes.length
                  : types.reduce((acc, type) => {
                      return (
                        acc +
                        quotes.filter((quote) => quote.type === type).length
                      );
                    }, 0);

              const isShowingAllQuotes =
                types.length === 0
                  ? totalQuotes <= 5
                  : types.length <= 3 &&
                    types.every((type) => {
                      const typeQuotes = quotes.filter(
                        (quote) => quote.type === type
                      );
                      return typeQuotes.length <= 3;
                    });

              return !isShowingAllQuotes ? (
                <Box
                  backgroundColor="secondary.main"
                  borderRadius={2}
                  right={10}
                  bottom={-10}
                  position="absolute"
                >
                  <IconButton size="small" onClick={() => setModalOpen(true)}>
                    <ZoomOutMapIcon sx={{ width: 20, height: 20 }} />
                  </IconButton>
                </Box>
              ) : null;
            })()}
        </Grid>
      </Grid>
    </Paper>
  );
};

const getHighlightColor = (theme, isHighlighted) => {
  const baseColor = theme.palette.primary.main;
  return isHighlighted ? alpha(baseColor, 0.3) : "transparent";
};

const DeBriefCaseContextItem = ({
  quote,
  opinion_url,
  quote_id = -1,
  quote_vote = 0,
  quote_type,
  isHighlighted,
}) => {
  const theme = useTheme();
  const [, copy] = useCopyToClipboard();
  const [open, setOpen] = useState(false);
  const quoteRef = useRef(null);

  const handleCopy = () => {
    const plainText =
      new DOMParser().parseFromString(quote, "text/html").body.textContent ||
      "";
    copy(plainText);
    setOpen(true);
  };

  useEffect(() => {
    if (quoteRef.current) {
      const highlightElements = quoteRef.current.querySelectorAll(
        ".highlight-on-hover"
      );
      const highlightColor = getHighlightColor(theme, isHighlighted);
      highlightElements.forEach((el) => {
        el.style.backgroundColor = highlightColor;
      });
    }
  }, [isHighlighted, theme]);

  return (
    <>
      <Stack
        alignItems={["flex-start", "flex-start", "center"]}
        flexDirection="row"
        gap={3}
        justifyContent="flex-start"
        textTransform={"none"}
        textAlign="left"
      >
        <Stack flexDirection={["column", "column", "row"]}>
          {quote_id !== -1 && (
            <>
              <QuoteVote id={quote_id} vote={quote_vote} type={quote_type} />
              <Divider sx={{ mb: 1, mt: 0.5 }} />
            </>
          )}
          <Stack>
            <Tooltip title="Copy Quote to Clipboard" placement="bottom">
              <IconButton onClick={handleCopy}>
                <ContentCopyIcon sx={{ width: 18, height: 18 }} />
              </IconButton>
            </Tooltip>
            {opinion_url && (
              <Tooltip title="Open Link to Opinion" placement="bottom">
                <IconButton
                  color="primary"
                  component={Link}
                  href={opinion_url}
                  target="_blank"
                >
                  <LinkIcon sx={{ width: 18, height: 18 }} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Box>
          <Typography
            variant="body2"
            component="span"
            ref={quoteRef}
            dangerouslySetInnerHTML={{ __html: quote }}
            sx={{
              "& .highlight-on-hover": {
                transition: "background-color 0.3s",
              },
            }}
          />
        </Box>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={2500}
        onClose={() => setOpen(false)}
        message="Copied to your clipboard"
      />
    </>
  );
};

const ExtraQuotes = ({
  measure,
  brief_version_id,
  brief_id,
  delta,
  modal,
  cutoff,
}) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => [
      "extra_quotes",
      brief_version_id,
      brief_id,
      measure.toLowerCase(),
      delta.toString(),
    ],
    [brief_version_id, brief_id, measure, delta]
  );

  const { data: extra } = useSuspenseQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const params = {
        brief_id,
        brief_version_id,
        category: measure.toLowerCase(),
      };

      const response = await api.get(
        `/briefs/cached_quotes?${decodeURIComponent(
          new URLSearchParams(params).toString()
        )}`,
        { signal }
      );

      return response;
    },
    retry: 12,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    return () => queryClient.cancelQueries({ queryKey });
  }, [queryClient, queryKey]);

  const extraQuotes = extra?.[0]?.quotes || [];

  return (
    <>
      {extraQuotes
        ?.slice(0, modal ? extraQuotes.length : cutoff)
        .map(
          (
            { quote, explanation, opinion_url, quote_id, quote_vote, type },
            index
          ) => (
            <DeBriefCaseContextItem
              key={`quote-context-${index}-${measure}-${delta}`}
              quote={quote ?? ""}
              explanation={explanation ?? ""}
              opinion_url={opinion_url ?? ""}
              quote_id={quote_id}
              quote_vote={quote_vote}
              quote_type={type}
            />
          )
        )}
    </>
  );
};

import * as api from "../../../services/api";
import { Autocomplete, TextField, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { OPINION_COUNT_LIMIT } from "../../../services/models";

export const DeBriefCaseFilters = ({
  handleSetSubject,
  handleSetJudges,
  versionIndex,
  cased,
  isJudge,
  isAggregate,
  isCourtX,
}) => {
  const type = isJudge ? "judge" : "court";
  const id = isJudge ? cased.judge.id : cased?.judge?.court?.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedVersionId = searchParams.get("version_id");

  const { data: subjects, isLoading: isLoadingSubjects } = useQuery({
    queryKey: ["subjects", id],
    queryFn: async () => await api.get(`/briefs/${type}-subjects/${id}`),
  });

  const { data: judges, isLoading: isLoadingJudges } = useQuery({
    queryKey: ["judges-names", "courts", id],
    queryFn: async () => await api.get(`/judges/names?court_id=${id}`),
  });

  const [selectedJudges, setSelectedJudges] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState(subjects || []);
  const [glow, setGlow] = useState(false);

  useEffect(() => {
    const fetchFilteredSubjects = async () => {
      if (selectedJudges.length > 0) {
        const judgeIds = selectedJudges.map((judge) => judge.id).join(",");
        const response = await api.get(
          `/briefs/court-subjects/${id}?judges=${judgeIds}`
        );
        setFilteredSubjects(response);
      } else {
        setFilteredSubjects(subjects);
      }
    };

    fetchFilteredSubjects();
  }, [selectedJudges, subjects, id]);

  useEffect(() => {
    if (glow) {
      const timer1 = setTimeout(() => setGlow(false), 2000);
      return () => clearTimeout(timer1);
    }
  }, [glow]);

  const handleJudgeChange = (event, value) => {
    if (selectedSubjects.length > 0) {
      setGlow(true);
    }

    setSelectedJudges(value);
    setSelectedSubjects([]);
    if (handleSetJudges) {
      handleSetJudges(event, value);
    }
    if (handleSetSubject) {
      handleSetSubject(event, []);
    }
  };

  const handleSubjectChange = (event, value) => {
    setSelectedSubjects(value);
    if (handleSetSubject) {
      handleSetSubject(event, value);
    }
  };

  const handleVersionChange = (event, value) => {
    if (value && cased.versions[value.id]) {
      const newVersionId = cased.versions[value.id].brief_version_id;
      setSearchParams({ version_id: newVersionId });
    }
  };

  const glowStyle = glow
    ? {
        border: "1px solid red",
        boxShadow: "0 0 5px red",
        transition: "border-color 0.5s, box-shadow 0.5s",
        animation: "glow 2s forwards",
      }
    : {};

  const options = useMemo(() => {
    return cased.versions.map(({ version }, index) => ({
      id: index,
      label: createVersionLabel(cased.versions[index]),
    }));
  }, [cased.versions]);

  const defaultValue = useMemo(() => {
    return (
      (selectedVersionId &&
        options.find((option) => option.id === +selectedVersionId)) ||
      options[versionIndex] ||
      null
    );
  }, [options, selectedVersionId, versionIndex]);

  return (
    <>
      <style>
        {`
          @keyframes glow {
            0% {
              border-color: transparent;
              box-shadow: none;
            }
            25% {
              border-color: red;
              box-shadow: 0 0 5px red;
            }
            75% {
              border-color: red;
              box-shadow: 0 0 5px red;
            }
            100% {
              border-color: transparent;
              box-shadow: none;
            }
          }
        `}
      </style>
      <Autocomplete
        disableClearable
        size="small"
        sx={{ flex: 1 }}
        onChange={handleVersionChange}
        autoHighlight
        value={defaultValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.label}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Versions"
            label="Version"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
      <Autocomplete
        loading={isLoadingSubjects}
        multiple
        filterSelectedOptions
        size="small"
        sx={{ flex: 1 }}
        onChange={handleSubjectChange}
        autoHighlight
        value={selectedSubjects}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.label}
        noOptionsText={
          filteredSubjects?.length === 0 ? "No subjects available" : "None"
        }
        options={
          (filteredSubjects || subjects)?.map((subject) => ({
            label: subject.subject_area,
            id: subject.id,
          })) || []
        }
        renderInput={(params) => (
          <TextField
            {...params}
            cy-data="header-filter-subject"
            placeholder="Subjects"
            label="Filter Scores by Subject"
            style={glowStyle}
            disabled={filteredSubjects?.length === 0}
          />
        )}
      />
      {!isJudge && (
        <Autocomplete
          disabled={isAggregate === 1 || isCourtX === 1}
          loading={isLoadingJudges}
          multiple
          size="small"
          sx={{ flex: 1 }}
          onChange={handleJudgeChange}
          autoHighlight
          options={
            judges?.map((judge) => ({
              label: judge.name,
              id: judge.id,
              judge_opinions_count: judge.judge_opinions_count,
            })) || []
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label}
          noOptionsText={"None"}
          filterSelectedOptions
          renderInput={(params) => (
            <Tooltip
              title="This is an aggregate court, which means the data for this court cannot be attributed to individual judges."
              disableHoverListener={!(isAggregate || isCourtX)}
              disableFocusListener={!(isAggregate || isCourtX)}
            >
              <TextField
                {...params}
                placeholder="Search Judge"
                label="Choose a judge"
              />
            </Tooltip>
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {option.label}
              {option.judge_opinions_count < OPINION_COUNT_LIMIT ? (
                <Tooltip
                  sx={{ marginLeft: 1, marginTop: -0.5 }}
                  title="Limited Sample"
                  placement="right"
                >
                  <IconButton size="small">
                    <InfoIcon color="primary" sx={{ width: 15, height: 15 }} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </li>
          )}
        />
      )}
    </>
  );
};

function createVersionLabel(version) {
  if (
    !version?.case_name &&
    !version?.client_number &&
    !version.matter_number
  ) {
    return `Version ${version.version}`;
  }

  return `V${version.version}${
    version.case_name ? ` – ${version.case_name}` : ""
  }${version.client_number ? ` – ${version.client_number}` : ""}${
    version.matter_number ? ` – ${version.matter_number}` : ""
  }`;
}

import * as api from "../../../services/api";

import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import {
  JudgesCard,
  ResetJudgesSearch,
  SearchEmpty,
  SearchJudges,
} from "../../components";
import { NavLink as RouterLink, useSearchParams } from "react-router-dom";
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  forwardRef,
} from "react";
import {
  canCompareJudgesState,
  compareJudgesState,
  drawerJudgeSearchState,
  judgesOnboardingState,
} from "../../../services/store";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { Default } from "../../layouts";
import { Error } from "../../views/Error";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Grid from "@mui/material/Unstable_Grid2";
import { TextSearchJudges } from "../../components/TextSearchJudges";
import { useInfiniteQuery } from "@tanstack/react-query";
import { JudgesOnboardingTour } from "../../components/JudgesOnboardingTour/JudgesOnboardingTour";

const JudgesView = () => {
  const [filterDrawerOpen, setFilterDrawerOpen] = useAtom(
    drawerJudgeSearchState
  );
  const [judgesOnboarding, setJudgesOnboarding] = useAtom(
    judgesOnboardingState
  );
  const [refs, setRefs] = useState({
    mainRef: null,
    cardRef: null,
    buttonsRef: null,
    searchRef: null,
  });

  const handleCloseFilterDrawer = () => setFilterDrawerOpen(false);

  const handleCloseOnboarding = () => {
    setJudgesOnboarding({
      isActive: false,
      currentStep: 1,
    });
  };

  const handleNextStep = () => {
    if (judgesOnboarding.currentStep === 5) {
      handleCloseOnboarding();
    } else {
      setJudgesOnboarding((prev) => ({
        ...prev,
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  const handleBackStep = () => {
    setJudgesOnboarding((prev) => ({
      ...prev,
      currentStep: prev.currentStep - 1,
    }));
  };

  const handleRefsReady = useCallback((newRefs) => {
    setRefs((prev) => {
      const hasChanges = Object.entries(newRefs).some(
        ([key, value]) => prev[key] !== value
      );
      return hasChanges ? newRefs : prev;
    });
  }, []);

  const handleStartOnboarding = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      setJudgesOnboarding({
        isActive: true,
        currentStep: 1,
      });
    }, 1000);
  };

  useEffect(() => {
    if (judgesOnboarding.currentStep === 5) {
      setFilterDrawerOpen(true);
    } else {
      setFilterDrawerOpen(false);
    }
  }, [judgesOnboarding.currentStep, setFilterDrawerOpen]);

  const highlightRef = useMemo(() => {
    if (!judgesOnboarding.isActive) return null;

    switch (judgesOnboarding.currentStep) {
      case 1:
        return refs.mainRef;
      case 2:
        return refs.cardRef;
      case 3:
        return refs.buttonsRef;
      case 4:
        return refs.searchRef;
      case 5:
        return document.querySelector(".MuiDrawer-root");
      default:
        return null;
    }
  }, [judgesOnboarding.isActive, judgesOnboarding.currentStep, refs]);

  return (
    <Default>
      {judgesOnboarding.isActive && (
        <JudgesOnboardingTour
          onClose={handleCloseOnboarding}
          currentStep={judgesOnboarding.currentStep}
          totalSteps={5}
          onNext={handleNextStep}
          onBack={handleBackStep}
          highlightRef={highlightRef}
        />
      )}
      <Drawer
        anchor={"right"}
        open={filterDrawerOpen}
        onClose={handleCloseFilterDrawer}
        variant="persistent"
      >
        <Suspense>
          <SearchJudges />
        </Suspense>
      </Drawer>
      <Error>
        <Fetch onRefsReady={handleRefsReady} />
      </Error>
    </Default>
  );
};

const Fetch = forwardRef(({ onRefsReady }, ref) => {
  const [page, setPage] = useState(1);
  const [search] = useSearchParams();

  const searchParamsString = new URLSearchParams(search).toString();

  const isComparing = useAtomValue(canCompareJudgesState);
  const comparing = useAtomValue(compareJudgesState);

  const setFilterDrawerOpen = useSetAtom(drawerJudgeSearchState);
  const theme = useTheme();

  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    isLoading,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: ["judges", searchParamsString],
    queryFn: async ({ pageParam = 1 }) =>
      await api.get(
        `/v3/judges?page=${pageParam}${
          searchParamsString ? `&${searchParamsString}` : ""
        }`
      ),
    getNextPageParam: (lastPage) => {
      return lastPage.num_pages === page ? undefined : page + 1;
    },
    throwOnError: true,
  });

  const handleOpenFilterDrawer = () => setFilterDrawerOpen(true);

  const handleFetchMore = useCallback(() => {
    const next = page + 1;
    setPage(next);
    fetchNextPage();
  }, [fetchNextPage, page]);

  useEffect(() => {
    if (isRefetching) {
      setPage(1);
    }
  }, [isRefetching]);

  const items = useMemo(() => {
    return data?.pages?.map((data) => data.judges).flat() || [];
  }, [data]);

  const firstCardRef = useRef(null);
  const mainContentWrapperRef = useRef(null);
  const buttonsRef = useRef(null);
  const searchAreaRef = useRef(null);

  useEffect(() => {
    if (onRefsReady) {
      onRefsReady({
        mainRef: mainContentWrapperRef,
        cardRef: firstCardRef,
        buttonsRef: buttonsRef,
        searchRef: searchAreaRef,
      });
    }
  }, [onRefsReady]);

  return (
    <Stack>
      <Box ref={mainContentWrapperRef}>
        <Box
          ref={searchAreaRef}
          paddingY={[2, 4]}
          paddingX={[0, 4]}
          position="sticky"
          top={0}
          backgroundColor="white"
          zIndex={10}
        >
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Stack
                alignItems={["stretch", "stretch", "center"]}
                direction={["column", "column", "row"]}
                justifyContent="space-between"
                paddingX={[2, 2, 0]}
                gap={[2, 3]}
              >
                <Stack
                  flex={1}
                  alignItems={["stretch", "stretch", "center"]}
                  direction={["row"]}
                  gap={[0, 0, 3]}
                >
                  <Box flex={1}>
                    <TextSearchJudges />
                  </Box>
                  <Stack alignItems="center" direction="row" gap={0}>
                    <Tooltip title="Filter Judges" placement="bottom">
                      <IconButton onClick={handleOpenFilterDrawer}>
                        <FilterAltIcon />
                      </IconButton>
                    </Tooltip>
                    {items && <ResetJudgesSearch />}
                  </Stack>
                </Stack>
                {isComparing && (
                  <Button
                    to="/judges/compare"
                    component={RouterLink}
                    variant="contained"
                    fullWidth={!smUp}
                    data-cy="main-compare-button-judges"
                  >
                    Compare ({comparing.length})
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box backgroundColor={["white", "grey.100"]} padding={[0, 4]}>
          {isLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="80vh"
            >
              <CircularProgress size="3rem" />
            </Box>
          )}
          {!isLoading && items?.length === 0 && (
            <Grid container rowSpacing={4}>
              <Grid xs={12}>
                <SearchEmpty text="No Judges found that fit your search." />
              </Grid>
            </Grid>
          )}
          <Grid container rowSpacing={4} xs={12}>
            {items?.map((id, index) => (
              <Grid key={id} xs={12}>
                <JudgesCard
                  ref={index === 0 ? firstCardRef : null}
                  buttonsRef={index === 0 ? buttonsRef : null}
                  id={id}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {(isFetchingNextPage || hasNextPage) && items && items?.length !== 0 && (
        <Stack sx={{ py: 4 }}>
          <Button onClick={handleFetchMore} variant="contained">
            {isFetchingNextPage ? (
              <CircularProgress color="inherit" size="3rem" />
            ) : (
              "Load More"
            )}
          </Button>
        </Stack>
      )}
    </Stack>
  );
});

export const Judges = () => <JudgesView />;

import * as api from "../api";
import { useQuery } from "@tanstack/react-query";

export const DEFAULT_CONFIG = {
  API_ERROR_INTERVAL: 60,
  UI_ERROR_INTERVAL: 30,
  MAINTENANCE_CHECK_INTERVAL: 5,
  MAINTENANCE_MAX_ATTEMPTS: 10,
};

const getConfig = async (config) => {
  const response = await api.get(`/config/${config}`);
  return response.config_value;
};

const useConfig = (paramName) => {
  return useQuery({
    queryKey: [paramName],
    queryFn: () => getConfig(paramName),
  });
};

export const useIsCiceroEnabled = () => {
  const { data } = useConfig("enable_cicero");
  return data === 1;
};

export const useLowSampleThreshold = () => {
  const { data } = useConfig("low_sample_threshold");
  return data;
};

export const useMaintenanceCheckInterval = () => {
  const { data } = useConfig("maintenance_check_interval");
  return data || DEFAULT_CONFIG.MAINTENANCE_CHECK_INTERVAL;
};

export const useMaintenanceMaxAttempts = () => {
  const { data } = useConfig("maintenance_max_attempts");
  return data || DEFAULT_CONFIG.MAINTENANCE_MAX_ATTEMPTS;
};

export const useApiErrorCheckInterval = () => {
  const { data } = useConfig("error_check_interval_api");
  return data || DEFAULT_CONFIG.API_ERROR_INTERVAL;
};

export const useUiErrorCheckInterval = () => {
  const { data } = useConfig("error_check_interval_ui");
  return data || DEFAULT_CONFIG.UI_ERROR_INTERVAL;
};

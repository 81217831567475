import React from "react";
import { Stack, Typography } from "@mui/material";
import { HTMLParser } from "../HTMLParser";
import { useJudgeCourtData } from "../../../services/hooks/useJudgeCourtData";
import { TagSection } from "./TagComponents";

export function JudgeCourtAnalysisSummary({ id, type, isJudgeX, isCourtX }) {
  const { tags, shouldHideTags } = useJudgeCourtData(id, type);

  const multiTags = tags?.tag_levels?.tag_list?.filter(
    (tag) => tag.tag_level === 99
  );
  const simpleTags = tags?.tag_levels?.tag_list?.filter(
    (tag) => tag.tag_level !== 99
  );

  return (
    <Stack sx={{ gap: 4, pb: 5 }}>
      {shouldHideTags(isCourtX, isJudgeX) ? (
        <Typography variant="body1">
          {`The sample size for this ${type} is too limited to accurately summarize. To request an increase in sample size, please contact Rhetoric with your request at `}
          <a href="mailto:support@userhetoric.com">support@userhetoric.com</a>.
        </Typography>
      ) : (
        <>
          <Stack sx={{ gap: 4 }}>
            {multiTags && multiTags.length > 0 && (
              <TagSection
                title="Combination Tags"
                tags={multiTags}
                isMulti={true}
              />
            )}
            {simpleTags && simpleTags.length > 0 && (
              <TagSection
                title="Basic Tags"
                tags={simpleTags}
                isMulti={false}
              />
            )}
          </Stack>
          {tags?.tag_levels?.tag_summary && (
            <HTMLParser content={tags.tag_levels.tag_summary} />
          )}
        </>
      )}
    </Stack>
  );
}

import { Box, Button, IconButton, Paper, Stack, useTheme } from "@mui/material";
import { makePrimaryCompare, removeItemAtIndex } from "../../../services/store";
import { useCallback, useMemo } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { DoctrinaCardAside } from "../../components";
import { compareDoctrinaState } from "../../../services/store";
import { hotjar } from "react-hotjar";
import { useAtom } from "jotai";

export const DoctrinaCompareCard = ({
  active,
  activeTab,
  doctrina,
  primaryDoctrina,
}) => {
  const { id } = doctrina;
  const [items, updateItems] = useAtom(compareDoctrinaState);
  const index = items.findIndex((item) => item === id);
  const theme = useTheme();

  const donutData = useMemo(() => {
    let result = {};
    let deltaResult = {};
    switch (activeTab) {
      case 0:
        result = doctrina.jurisprudence;
        deltaResult = active
          ? {}
          : Object.keys(doctrina.jurisprudence || {}).reduce((acc, key) => {
              acc[key] =
                doctrina.jurisprudence[key] -
                (primaryDoctrina.jurisprudence[key] || 0);
              return acc;
            }, {});
        break;
      case 1:
        result = doctrina.tone;
        deltaResult = active
          ? {}
          : Object.keys(doctrina.tone || {}).reduce((acc, key) => {
              acc[key] = doctrina.tone[key] - (primaryDoctrina.tone[key] || 0);
              return acc;
            }, {});
        break;
      case 2:
        result = doctrina.sentiment;
        deltaResult = active
          ? {}
          : Object.keys(doctrina.sentiment || {}).reduce((acc, key) => {
              acc[key] =
                doctrina.sentiment[key] - (primaryDoctrina.sentiment[key] || 0);
              return acc;
            }, {});
        break;
      default:
        result = doctrina.jurisprudence;
        deltaResult = active
          ? {}
          : Object.keys(doctrina.jurisprudence || {}).reduce((acc, key) => {
              acc[key] =
                doctrina.jurisprudence[key] -
                (primaryDoctrina.jurisprudence[key] || 0);
              return acc;
            }, {});
    }
    return { values: result, deltas: deltaResult };
  }, [activeTab, doctrina, primaryDoctrina, active]);

  const politicalAffiliationDeltas = useMemo(() => {
    if (active) return {};
    return Object.keys(doctrina.political_affiliations || {}).reduce(
      (acc, key) => {
        acc[key] =
          doctrina.political_affiliations[key] -
          (primaryDoctrina.political_affiliations[key] || 0);
        return acc;
      },
      {}
    );
  }, [
    active,
    doctrina.political_affiliations,
    primaryDoctrina.political_affiliations,
  ]);

  const deleteItem = useCallback(() => {
    const updated = removeItemAtIndex(items, index);
    updateItems(updated);
    hotjar.event("compare-courts-remove");
  }, [items, index, updateItems]);

  const makePrimary = useCallback(() => {
    const updated = makePrimaryCompare(items, id);
    updateItems(updated);
    hotjar.event("compare-courts-change-primary");
  }, [id, items, updateItems]);

  return (
    <Paper
      elevation={1}
      sx={{
        height: "100%",
        minHeight: "60rem",
        border: active ? `3px solid ${theme.palette.primary.main}` : "none",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box position="absolute" top={3} right={3} zIndex={1}>
        <IconButton onClick={deleteItem}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack
        backgroundColor="white"
        color={active ? "white" : null}
        position="relative"
        height="100%"
      >
        <Box paddingTop={2} flex={1} display="flex" flexDirection="column">
          <DoctrinaCardAside
            simple
            compact
            active={active}
            filled={active ? false : true}
            showPolarity={false}
            donutData={donutData.values}
            donutDeltas={donutData.deltas}
            politicalAffiliationDeltas={politicalAffiliationDeltas}
            isCourtX={doctrina.type === "Court X"}
            isCompare={true}
            isPrimary={active}
            {...doctrina}
          />
        </Box>
      </Stack>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        sx={{
          marginLeft: active ? "-3px" : 0,
          marginRight: active ? "-3px" : 0,
          marginBottom: active ? "-3px" : 0,
        }}
      >
        {active ? (
          <Button
            variant="contained"
            fullWidth
            disabled
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              "&.Mui-disabled": {
                backgroundColor: "primary.main",
                color: "white",
              },
              borderRadius: 0,
            }}
          >
            Primary
          </Button>
        ) : (
          <Button
            onClick={makePrimary}
            variant="contained"
            fullWidth
            data-cy="make-primary-btn-doctrina"
            sx={{
              borderRadius: 0,
            }}
          >
            Make Primary
          </Button>
        )}
      </Box>
    </Paper>
  );
};

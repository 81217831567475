import { Paper, Stack, Typography, useTheme } from "@mui/material";
import {
  StatPieGraphText,
  StatRadarGraph,
  normalizeComparedData,
} from "../../components";

import Grid from "@mui/material/Unstable_Grid2";
import { useContainerQuery } from "react-container-query";
import { useMemo } from "react";

const query = {
  "width-between-425-and-520": {
    minWidth: 425,
    maxWidth: 520,
  },
};

export const DeBriefCaseItem = ({
  versionIndex,
  versions,
  judge,
  status,
  type,
}) => {
  const theme = useTheme();
  const [params, containerRef] = useContainerQuery(query);

  const isJudge = useMemo(() => {
    return judge?.id !== -1;
  }, [judge?.id]);

  if (status === "PENDING") {
    return (
      <Paper elevation={3}>
        <Stack gap={3} padding={4}>
          <Typography>
            Brief is being analyzed. Please check back soon.
          </Typography>
        </Stack>
      </Paper>
    );
  }

  return (
    <Paper
      ref={containerRef}
      elevation={3}
      sx={{ height: "100%", display: "flex", alignItems: "center" }}
    >
      <Stack gap={3} padding={4} sx={{ width: "100%" }}>
        <Grid container>
          <Grid
            container
            xs={12}
            spacing={2}
            md={params["width-between-425-and-520"] ? 12 : 5}
          >
            {versions.length &&
              Object.keys(versions[versionIndex][type]).map((key) => (
                <Grid
                  xs={6}
                  md={params["width-between-425-and-520"] ? 4 : 6}
                  key={key}
                >
                  <StatPieGraphText
                    value={versions[versionIndex][type][key]}
                    delta={versions[versionIndex][`${type}_delta`][key]}
                    label={key}
                    comparing={false}
                    filled={true}
                    showPolarity={false}
                    isCompare={true}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid xs={12} md={params["width-between-425-and-520"] ? 12 : 7}>
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              {versions.length && (
                <StatRadarGraph
                  data={normalizeComparedData(
                    versions[versionIndex][type],
                    judge[type]
                  )}
                  keys={[isJudge ? "judge" : "court", "brief"]}
                  indexBy="jurisprudence"
                  colors={[theme.palette.primary.main, theme.palette.grey[500]]}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

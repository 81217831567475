import { Box, Button, Collapse, Stack } from "@mui/material";
import { DeBriefCard, DebriefOnboardingTour } from "../../components";
import Grid from "@mui/material/Unstable_Grid2";
import { NavLink } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { useEffect, useRef } from "react";

export const DeBriefItems = ({ items, setSelected, selected, onRefsReady }) => {
  const newCaseButtonRef = useRef(null);
  const firstCardRef = useRef(null);
  const openCaseButtonRef = useRef(null);
  const actionButtonsRef = useRef(null);

  useEffect(() => {
    if (onRefsReady) {
      onRefsReady({
        newCaseButtonRef,
        firstCardRef,
        openCaseButtonRef,
        actionButtonsRef,
      });
    }
  }, [onRefsReady]);

  return (
    <>
      <Stack>
        <Box
          backgroundColor="white"
          paddingY={4}
          paddingX={[0, 4]}
          position="sticky"
          top={0}
          zIndex={10}
        >
          <Grid container xs={12}>
            <Grid xs={12}>
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  ref={newCaseButtonRef}
                  component={NavLink}
                  to="/debrief/create"
                  variant="contained"
                  data-cy="new-case-button"
                >
                  New Case
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box backgroundColor="grey.100" paddingY={4} paddingX={[0, 4]}>
          <Grid container rowSpacing={4} xs={12} component={TransitionGroup}>
            {items?.map((id, index) => (
              <Collapse key={id} sx={{ width: "100%" }}>
                <Grid xs={12}>
                  <DeBriefCard
                    ref={index === 0 ? firstCardRef : null}
                    openCaseButtonRef={index === 0 ? openCaseButtonRef : null}
                    actionButtonsRef={index === 0 ? actionButtonsRef : null}
                    handleSelect={setSelected}
                    active={selected === id}
                    index={index}
                    id={id}
                  />
                </Grid>
              </Collapse>
            ))}
          </Grid>
        </Box>
      </Stack>
    </>
  );
};

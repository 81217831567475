import { Stack, useTheme } from "@mui/material";
import {
  StatPieGraphText,
  StatRadarGraph,
  normalizeComparedData,
} from "../../components";

import Grid from "@mui/material/Unstable_Grid2";
import { useContainerQuery } from "react-container-query";
import { useMemo } from "react";

const query = {
  "width-between-450-and-520": {
    minWidth: 450,
    maxWidth: 520,
  },
};

export const DeBriefCardItem = ({ judge, type, scores }) => {
  const theme = useTheme();
  const [params, containerRef] = useContainerQuery(query);
  const isJudge = useMemo(() => {
    return judge?.id !== -1;
  }, [judge?.id]);

  return (
    <Stack ref={containerRef} gap={5}>
      <Grid container>
        <Grid
          container
          xs={12}
          md={params["width-between-450-and-520"] ? 12 : 5}
        >
          {Object.keys(judge[type]).map((key) => (
            <Grid xs={6} md={6} key={key}>
              <StatPieGraphText
                value={judge[type][key]}
                label={key}
                comparing={false}
                filled={true}
                isCompare={true}
                overrideColor={theme.palette.warning.main}
              />
            </Grid>
          ))}
        </Grid>
        <Grid xs={12} md={params["width-between-450-and-520"] ? 12 : 7}>
          <Stack
            sx={{
              alignItems: "center",
            }}
          >
            <StatRadarGraph
              data={normalizeComparedData(scores, judge[type])}
              keys={[isJudge ? "judge" : "court", "brief"]}
              indexBy="jurisprudence"
              colors={[theme.palette.grey[500], theme.palette.primary.main]}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { Default as Layout } from "../../layouts";
import { useCompletedAssignments } from "../../../services/queries";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export const CiceroCase = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: completedAssignments,
    isLoading,
    error,
  } = useCompletedAssignments({
    suspense: false,
  });

  const caseParams = useMemo(() => {
    if (!completedAssignments) return null;

    const currentCase = completedAssignments.find(
      (assignment) => assignment.activity_id === id
    );

    if (!currentCase) return null;

    const bongoIntegration = currentCase.bongo_integration || {};
    const { params } = currentCase;

    for (const key in params) {
      if (
        params.hasOwnProperty(key) &&
        typeof params[key] === "object" &&
        !Array.isArray(params[key]) &&
        params[key] !== null
      ) {
        params[key] = JSON.stringify(params[key]);
      }
    }

    return {
      ...params,
      bongo_target: currentCase.bongo_target,
      region: bongoIntegration.region,
      code: bongoIntegration.code,
      key: bongoIntegration.key,
      secret: bongoIntegration.secret,
      custom_launch_view: "activity",
    };
  }, [completedAssignments, id]);

  if (isLoading) {
    return (
      <Layout>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          backgroundColor="rgba(255,255,255,.98)"
        >
          <img src="/gifs/loading-brief.gif" alt="loading" width={250} />
          <Typography textAlign="center" variant="body1">
            Loading your recording. Please wait...
          </Typography>
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Typography color="error">
          Error loading case: {error.message}
        </Typography>
      </Layout>
    );
  }

  if (!caseParams) {
    return (
      <Layout>
        <Typography>Case not found</Typography>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box padding={4}>
        <Box position="relative" mb={2}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/cicero")}
            variant="outlined"
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            Back
          </Button>
          <Typography variant="h4" textAlign="center">
            {caseParams?.resource_link_title}
          </Typography>
        </Box>
        {caseParams && (
          <Box
            component="iframe"
            src={`/bongo-index.html?${new URLSearchParams(
              caseParams
            ).toString()}`}
            sx={{
              width: "100%",
              height: "calc(100vh - 120px)",
              border: "1px solid lightgrey",
              backgroundColor: "lightgrey",
              marginTop: 2,
            }}
          />
        )}
      </Box>
    </Layout>
  );
};

export default CiceroCase;

import { Box, Button, IconButton, Paper, Stack, useTheme } from "@mui/material";
import { makePrimaryCompare, removeItemAtIndex } from "../../../services/store";
import { useCallback, useMemo } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { JudgesCardAside } from "../../components";
import { compareJudgesState } from "../../../services/store";
import { hotjar } from "react-hotjar";
import { useAtom } from "jotai";

const shouldDisplayValue = (value) => {
  if (typeof value === "number") return true;
  const unwantedValues = [
    "Unknown",
    "Unknown ()",
    "Unknown (9999)",
    "unknown",
    "unknown ()",
    "unknown (9999)",
    "N/A (9999)",
    "N/A",
    "n/a",
    "N/A ()",
    "na",
    "",
  ];

  let v = "";

  if (typeof value === "string") {
    v = value == null ? "" : String(value).trim();
  }

  if (typeof value === "object") {
    if (value?.year && value?.rating) {
      v = `${value.rating} (${value.year})`;
    }
  }

  return v !== "" && !unwantedValues.includes(v);
};

export const JudgesCompareCard = ({
  active,
  activeTab,
  judge,
  primaryJudge,
}) => {
  const { id } = judge;
  const [items, updateItems] = useAtom(compareJudgesState);
  const index = items.findIndex((item) => item === id);
  const theme = useTheme();

  const donutData = useMemo(() => {
    let result = {};
    let deltaResult = {};
    switch (activeTab) {
      case 0:
        result = judge.jurisprudence;
        deltaResult = active
          ? {}
          : Object.keys(judge.jurisprudence || {}).reduce((acc, key) => {
              acc[key] =
                judge.jurisprudence[key] -
                (primaryJudge.jurisprudence[key] || 0);
              return acc;
            }, {});
        break;
      case 1:
        result = judge.tone;
        deltaResult = active
          ? {}
          : Object.keys(judge.tone || {}).reduce((acc, key) => {
              acc[key] = judge.tone[key] - (primaryJudge.tone[key] || 0);
              return acc;
            }, {});
        break;
      case 2:
        result = judge.sentiment;
        deltaResult = active
          ? {}
          : Object.keys(judge.sentiment || {}).reduce((acc, key) => {
              acc[key] =
                judge.sentiment[key] - (primaryJudge.sentiment[key] || 0);
              return acc;
            }, {});
        break;
      default:
        result = judge.jurisprudence;
        deltaResult = active
          ? {}
          : Object.keys(judge.jurisprudence || {}).reduce((acc, key) => {
              acc[key] =
                judge.jurisprudence[key] -
                (primaryJudge.jurisprudence[key] || 0);
              return acc;
            }, {});
    }
    return { values: result, deltas: deltaResult };
  }, [activeTab, judge, primaryJudge, active]);

  const deleteItem = useCallback(() => {
    const updated = removeItemAtIndex(items, index);
    updateItems(updated);
  }, [items, index, updateItems]);

  const makePrimary = useCallback(() => {
    const updated = makePrimaryCompare(items, id);
    updateItems(updated);
    hotjar.event("compare-judges-change-primary");
  }, [id, items, updateItems]);

  const filteredJudge = Object.entries(judge).reduce((acc, [key, value]) => {
    if (key === "jurisprudence") {
      acc[key] = Object.entries(value).reduce((jurAcc, [jurKey, jurValue]) => {
        if (shouldDisplayValue(jurValue)) {
          jurAcc[jurKey] = jurValue;
        }
        return jurAcc;
      }, {});
    } else if (shouldDisplayValue(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return (
    <Paper
      elevation={1}
      sx={{
        height: "100%",
        minHeight: "60rem",
        border: active ? `3px solid ${theme.palette.primary.main}` : "none",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Stack
        backgroundColor="white"
        color={active ? "white" : null}
        height="100%"
        position="relative"
      >
        <Box position="absolute" top={3} right={3}>
          <IconButton onClick={deleteItem}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box flex={1} paddingTop={2} display="flex" flexDirection="column">
          <JudgesCardAside
            simple
            compact
            isPrimary={active}
            active={active}
            filled={active ? false : true}
            showPolarity={false}
            donutData={donutData.values}
            donutDeltas={donutData.deltas}
            isJudgeX={judge.is_judge_x}
            {...filteredJudge}
            isCompare={true}
          />
        </Box>
      </Stack>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        sx={{
          marginLeft: active ? "-3px" : 0,
          marginRight: active ? "-3px" : 0,
          marginBottom: active ? "-3px" : 0,
        }}
      >
        {active ? (
          <Button
            variant="contained"
            fullWidth
            disabled
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              "&.Mui-disabled": {
                backgroundColor: "primary.main",
                color: "white",
              },
              borderRadius: 0,
            }}
          >
            Primary
          </Button>
        ) : (
          <Button
            onClick={makePrimary}
            variant="contained"
            fullWidth
            data-cy="make-primary-btn-judges"
            sx={{
              borderRadius: 0,
            }}
          >
            Make Primary
          </Button>
        )}
      </Box>
    </Paper>
  );
};

import React, { useRef, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link as RouterLink } from "react-router-dom";

export const CiceroEmpty = ({ onRefsReady }) => {
  const newRecordingButtonRef = useRef(null);
  const emptyRecordingAreaRef = useRef(null);

  useEffect(() => {
    if (onRefsReady) {
      onRefsReady({
        newRecordingButtonRef,
        firstCardRef: null,
        emptyRecordingAreaRef,
      });
    }
  }, [onRefsReady]);

  return (
    <Stack>
      <Box
        backgroundColor="white"
        paddingY={4}
        paddingX={[0, 4]}
        position="sticky"
        top={0}
        zIndex={10}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                ref={newRecordingButtonRef}
                component={RouterLink}
                to="/cicero/create"
                variant="contained"
                color="primary"
              >
                New Recording
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box backgroundColor="grey.100" paddingY={4} paddingX={[0, 4]}>
        <Stack
          ref={emptyRecordingAreaRef}
          border="2px dashed grey"
          borderColor="grey.500"
          borderRadius={3}
          height={400}
          justifyContent="center"
          alignItems="center"
          padding={4}
          width="100%"
        >
          <Typography variant="h3" color="text.secondary" align="center">
            Start your first recording
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

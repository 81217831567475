import { Box, Typography } from "@mui/material";

import { DoctrinaCard } from "../../components";
import { JudgeCourtSummaryTabs } from "../../components/JudgeCourtSummaryTabs";
import { courtSubjectsAtom } from "../../../services/store";
import { doctrinaOptions } from "../../../services/queries";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";

export const CourtContainer = ({ id }) => {
  const subjects = useAtomValue(courtSubjectsAtom(id));

  const parameters = decodeURIComponent(
    new URLSearchParams({
      subject: subjects.map((subject) => subject.id).join(","),
    }).toString()
  );

  const { data: court, error } = useSuspenseQuery(
    doctrinaOptions(id, parameters)
  );

  const isAggregate = useMemo(() => court.is_aggregate === 1, [court]);
  const isCourtX = useMemo(() => court.is_court_x === 1, [court]);

  if (error) {
    return <Typography>Error fetching data: {error.message}</Typography>;
  }

  if (!court) {
    return (
      <Box paddingTop={4} paddingX={4}>
        <Typography>No data found</Typography>
      </Box>
    );
  }

  return (
    <>
      <DoctrinaCard id={id} isDetailPage />
      <JudgeCourtSummaryTabs
        judgeCourtId={id}
        isAggregateDoctrina={isAggregate}
        isCourtX={isCourtX}
      />
    </>
  );
};

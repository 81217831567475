import { fetchAuthSession } from "aws-amplify/auth";
import * as api from "./api";
import { DEFAULT_CONFIG } from "./hooks/useConfig";

const STORAGE_KEY = "rhetoric_errors";
const CONFIG_REFRESH_INTERVAL = 5 * 60 * 1000;

let errorIntervals = {
  API: DEFAULT_CONFIG.API_ERROR_INTERVAL,
  UI: DEFAULT_CONFIG.UI_ERROR_INTERVAL,
  lastFetched: 0,
};

let intervalFetchPromise = null;

const fetchErrorIntervals = async () => {
  try {
    const now = Date.now();
    if (now - errorIntervals.lastFetched > CONFIG_REFRESH_INTERVAL) {
      if (intervalFetchPromise) {
        await intervalFetchPromise;
        return;
      }

      intervalFetchPromise = Promise.all([
        api.get("/config/error_check_interval_api"),
        api.get("/config/error_check_interval_ui"),
      ]);

      const [apiInterval, uiInterval] = await intervalFetchPromise;

      errorIntervals = {
        API: apiInterval.config_value || DEFAULT_CONFIG.API_ERROR_INTERVAL,
        UI: uiInterval.config_value || DEFAULT_CONFIG.UI_ERROR_INTERVAL,
        lastFetched: now,
      };

      intervalFetchPromise = null;
    }
  } catch (e) {
    console.warn("Failed to fetch error intervals:", e);
    intervalFetchPromise = null;
  }
};

if (Date.now() - errorIntervals.lastFetched > CONFIG_REFRESH_INTERVAL) {
  fetchErrorIntervals();
}

const shouldReportError = (error, type) => {
  try {
    const signature =
      type === "API"
        ? `${error.endpoint}-${error.method}-${error.status_code}`
        : `${error.message}-${window.location.href}`;

    const now = Date.now();
    const storedError = localStorage.getItem(`${STORAGE_KEY}:${signature}`);

    if (storedError) {
      const lastReported = parseInt(storedError);
      const interval = errorIntervals[type] * 1000;

      if (now - lastReported < interval) {
        return false;
      }
    }

    localStorage.setItem(`${STORAGE_KEY}:${signature}`, now.toString());
    return true;
  } catch (e) {
    return true;
  }
};

export const reportErrorToBackend = async (error, type = "UI") => {
  try {
    await fetchErrorIntervals();

    if (!shouldReportError(error, type)) {
      return;
    }

    const session = await fetchAuthSession();
    const token = session.tokens.idToken.toString();
    const sessionId = session.tokens.accessToken.payload.event_id;

    const { payload: userInfo } = session.tokens.idToken;

    const basePayload = {
      level: "ERROR",
      url: window.location.href,
      type,
      message: error.message || "Unknown error",
      name: error.name || "Error",
      user_email: userInfo.email,
    };

    const payload =
      type === "API"
        ? {
            ...basePayload,
            status_code: error.code,
            endpoint: error.endpoint,
            method: error.method,
            response: error.response,
          }
        : {
            ...basePayload,
            stack: error.stack,
            componentStack: error.componentStack,
          };

    await fetch(`${process.env.REACT_APP_API_ENDPOINT}/audit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Rhetoric-Session-ID": sessionId,
      },
      body: JSON.stringify(payload),
    });
  } catch (reportError) {
    console.error(`Failed to report ${type} error to backend:`, reportError);
  }
};

import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import { homeOnboardingState } from "../../../services/store";
import { Default } from "../../layouts";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import {
  ProductCard,
  HomeOnboardingTour,
  HelpDialog,
  BrowserWarning,
} from "../../components";
import { useAuth } from "../../../services/auth";
import { useIsCiceroEnabled } from "../../../services/hooks";
import { useRef, useEffect } from "react";
import { helpDialogOpenAtom } from "../../components/HelpDialog/HelpDialog";

export const Home = () => {
  const theme = useTheme();
  const { handleLogout } = useAuth();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const isCiceroEnabled = useIsCiceroEnabled();
  const [homeOnboarding, setHomeOnboarding] = useAtom(homeOnboardingState);
  const productsRef = useRef(null);
  const navigationRef = useRef(null);
  const videoButtonRef = useRef(null);
  const helpButtonRef = useRef(null);
  const setHelpDialogOpen = useSetAtom(helpDialogOpenAtom);
  const helpDialogRef = useRef(null);

  useEffect(() => {
    if (homeOnboarding.currentStep === 5) {
      setHelpDialogOpen(true);
    } else {
      setHelpDialogOpen(false);
    }
  }, [homeOnboarding.currentStep, setHelpDialogOpen]);

  const handleCloseOnboarding = () => {
    setHomeOnboarding({
      isActive: false,
      currentStep: 1,
    });
  };

  const handleNextStep = () => {
    if (homeOnboarding.currentStep === 5) {
      handleCloseOnboarding();
    } else {
      setHomeOnboarding((prev) => ({
        ...prev,
        currentStep: prev.currentStep + 1,
      }));
    }
  };

  const handleBackStep = () => {
    setHomeOnboarding((prev) => ({
      ...prev,
      currentStep: prev.currentStep - 1,
    }));
  };

  const handleStartOnboarding = () => {
    setHomeOnboarding({
      isActive: true,
      currentStep: 1,
    });
  };

  return (
    <Default
      navigationRef={navigationRef}
      videoButtonRef={videoButtonRef}
      helpButtonRef={helpButtonRef}
    >
      {homeOnboarding.isActive && (
        <HomeOnboardingTour
          onClose={handleCloseOnboarding}
          currentStep={homeOnboarding.currentStep}
          totalSteps={5}
          onNext={handleNextStep}
          onBack={handleBackStep}
          highlightRef={
            homeOnboarding.currentStep === 1
              ? productsRef
              : homeOnboarding.currentStep === 2
              ? navigationRef
              : homeOnboarding.currentStep === 3
              ? videoButtonRef
              : homeOnboarding.currentStep === 4
              ? helpButtonRef
              : helpDialogRef
          }
        />
      )}
      <HelpDialog ref={helpDialogRef} />

      <Box
        padding={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Grid
          ref={productsRef}
          alignItems="stretch"
          container
          rowSpacing={3}
          columnSpacing={5}
        >
          <Grid xs={12}>
            <Typography variant="h1">Your Products</Typography>
          </Grid>
          <Grid xs={12} lg={6}>
            <ProductCard
              heading="Doctrina"
              content="Doctrina helps you get a holistic view of courts, their composition, and their language preferences."
              action={
                <Button
                  component={Link}
                  to="/doctrina"
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  View
                </Button>
              }
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <ProductCard
              heading="DeBrief"
              content="DeBrief is a tool that helps you make legal arguments that are custom tailored for the judges handling your cases."
              action={
                <Button
                  component={Link}
                  to="/debrief"
                  color="secondary"
                  size="small"
                  variant="contained"
                >
                  View
                </Button>
              }
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <ProductCard
              heading="Judges"
              content="Judges allows you to search for individual judges based on their philosophies of the law, political affiliations, and more."
              action={
                <Button
                  component={Link}
                  to="/judges"
                  color="secondary"
                  size="small"
                  variant="contained"
                >
                  View
                </Button>
              }
            />
          </Grid>
          {isCiceroEnabled && (
            <Grid xs={12} lg={6}>
              <ProductCard
                heading="Cicero"
                content="Cicero is a tool that helps you practice and refine your oral presentations."
                action={
                  <Button
                    component={Link}
                    to="/cicero"
                    size="small"
                    color="secondary"
                    variant="contained"
                  >
                    View
                  </Button>
                }
              />
            </Grid>
          )}
          {!md && (
            <Grid xs={12}>
              <Stack justifyContent="flex-end">
                <Button onClick={handleLogout}>Logout</Button>
              </Stack>
            </Grid>
          )}
        </Grid>
        <BrowserWarning />
      </Box>
    </Default>
  );
};

import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export const TRANSITION = "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)";

export const StepPaper = ({ title, description, sx = {}, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    "(max-height: 1000px), (max-width: 1800px)"
  );

  const commonPaperStyles = {
    p: isSmallScreen ? 3 : 5,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    maxWidth: isSmallScreen ? 400 : 500,
    minHeight: isSmallScreen ? 200 : 280,
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    py: isSmallScreen ? 2 : 3,
    ...sx,
  };

  const smallScreenTypographyStyles = {
    h4: {
      fontSize: isSmallScreen ? "1.3rem !important" : "2.125rem",
      marginBottom: isSmallScreen ? "0.5rem !important" : "2rem",
    },
    body1: {
      fontSize: isSmallScreen ? "0.9rem !important" : "1rem",
      lineHeight: isSmallScreen ? 1.4 : 1.5,
      marginBottom: isSmallScreen ? "0.5rem" : "1rem",
    },
  };

  return (
    <Paper sx={commonPaperStyles} {...props}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={smallScreenTypographyStyles.h4}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={smallScreenTypographyStyles.body1}
      >
        {description}
      </Typography>
    </Paper>
  );
};

export const StepArrow = ({ rotation = 0, sx = {} }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    "(max-height: 1000px), (max-width: 1800px)"
  );

  const commonArrowStyles = {
    color: theme.palette.primary.main,
    fontSize: isSmallScreen ? 40 : 80,
    filter: `drop-shadow(0 0 8px ${theme.palette.text.white})`,
    transition: TRANSITION,
    transform: `rotate(${rotation}deg)`,
    ...sx,
  };

  return <ArrowUpwardIcon sx={commonArrowStyles} />;
};

export const ContentWrapper = ({ children, sx = {} }) => (
  <Box
    sx={{
      position: "fixed",
      zIndex: 1302,
      display: "flex",
      alignItems: "center",
      transition: TRANSITION,
      ...sx,
    }}
  >
    {children}
  </Box>
);

export const TourNavigation = ({
  currentStep,
  totalSteps,
  onNext,
  onBack,
  onClose,
  closeButtonTopPosition = 16,
}) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: closeButtonTopPosition,
          right: 16,
          zIndex: 1302,
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "4px",
          transition: TRANSITION,
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            color: "grey.500",
            border: "2px solid",
            borderColor: "grey.500",
            padding: 1,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          aria-label="close onboarding"
        >
          <CloseIcon sx={{ fontSize: 28 }} />
        </IconButton>
      </Box>

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          p: 2,
          borderRadius: "4px 4px 0 0",
          backgroundColor: "background.paper",
          zIndex: 1302,
          minWidth: "400px",
          maxWidth: "600px",
          width: "40%",
          transition: TRANSITION,
        }}
        elevation={3}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Button
            variant="outlined"
            disabled={currentStep === 1}
            onClick={onBack}
          >
            Back
          </Button>

          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Step {currentStep}/{totalSteps}
          </Typography>

          <Button variant="contained" onClick={onNext}>
            {currentStep === totalSteps ? "Finish" : "Next"}
          </Button>
        </Stack>
      </Paper>
    </>
  );
};

import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import {
  TRANSITION,
  StepPaper,
  StepArrow,
  ContentWrapper,
  TourNavigation,
} from "./components";

export const OnboardingTour = ({
  onClose,
  currentStep = 1,
  totalSteps,
  onNext,
  onBack,
  highlightRef,
  steps,
  renderStep,
  closeButtonTopPosition,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    "(max-height: 1000px), (max-width: 1800px)"
  );
  const [highlightBox, setHighlightBox] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const updateHighlightPosition = () => {
      if (highlightRef?.current) {
        const rect = highlightRef.current.getBoundingClientRect();
        setHighlightBox({
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
        });
      }
    };

    updateHighlightPosition();
    window.addEventListener("resize", updateHighlightPosition);
    return () => window.removeEventListener("resize", updateHighlightPosition);
  }, [highlightRef]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const commonHighlightStyles = {
    position: "fixed",
    top: highlightBox.top - 16,
    left: highlightBox.left - 16,
    width: highlightBox.width + 32,
    height: highlightBox.height + 32,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
    boxShadow: `0 0 15px 1px ${theme.palette.primary.main}`,
    zIndex: 1301,
    transition: TRANSITION,
    backgroundColor: "transparent",
    clipPath: "inset(-20px -20px -20px -20px)",
    pointerEvents: "none",
    backdropFilter: "brightness(2)",
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1300,
          transition: TRANSITION,
        }}
      />

      {renderStep({
        step: currentStep,
        highlightBox,
        commonHighlightStyles,
        isSmallScreen,
        StepPaper,
        StepArrow,
        ContentWrapper,
        steps,
        theme,
      })}

      <TourNavigation
        currentStep={currentStep}
        totalSteps={totalSteps}
        onNext={onNext}
        onBack={onBack}
        onClose={onClose}
        closeButtonTopPosition={closeButtonTopPosition}
      />
    </>
  );
};

import { Box } from "@mui/material";
import { OnboardingTour } from "../common/OnboardingTour/OnboardingTour";

const ONBOARDING_STEPS = {
  1: {
    title: "Welcome to DeBrief",
    description:
      "Enhance your legal writing with DeBrief - a powerful tool that analyzes your briefs against the language preferences of specific judges or courts to help craft more persuasive arguments.",
  },
  2: {
    title: "Create New Cases",
    description:
      "Begin your analysis by clicking here to upload a new brief. DeBrief will evaluate your writing against your selected judge or court's preferences.",
  },
  3: {
    title: "DeBrief Cards",
    description:
      "Each card provides a comprehensive overview of your brief's analysis, including the overall Rhetoric Score and detailed breakdowns across Jurisprudence, Sentiment, and Tone metrics.",
  },
  4: {
    title: "Access Case Details",
    description:
      "Click 'Open Case' to dive deeper into your brief's analysis, exploring detailed insights and specific recommendations based on your target judge or court's writing style.",
  },
  5: {
    title: "Manage Cases",
    description:
      "Maintain your dashboard by editing case information or removing completed matters. Remember to use the delete function carefully, as this action cannot be undone.",
  },
};

const renderStep = ({
  step,
  highlightBox,
  commonHighlightStyles,
  isSmallScreen,
  StepPaper,
  StepArrow,
  ContentWrapper,
  steps,
}) => {
  if (step === 1) {
    return (
      <>
        <Box sx={{ ...commonHighlightStyles, height: `calc(100vh - 80px)` }} />
        <ContentWrapper
          sx={{
            bottom: 80,
            left: "50%",
            transform: "translateX(-50%)",
            flexDirection: "column",
            gap: isSmallScreen ? 2 : 3,
            maxWidth: "600px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          <StepPaper {...steps[1]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 2) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            top: highlightBox.top + highlightBox.height + 120,
            left: highlightBox.left + highlightBox.width / 2 - 380,
            transform: "translateX(-50%)",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: isSmallScreen ? 2 : 3,
            maxWidth: "500px",
          }}
        >
          <StepPaper {...steps[2]} />
          <StepArrow
            rotation={45}
            sx={{
              marginTop: "-80px",
              marginRight: "-20px",
            }}
          />
        </ContentWrapper>
      </>
    );
  }

  if (step === 3) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            ...(isSmallScreen
              ? {
                  bottom: 80,
                  left: "50%",
                  transform: "translateX(-50%)",
                  flexDirection: "column",
                  gap: 1,
                }
              : {
                  top: highlightBox.top + highlightBox.height + 40,
                  left: "50%",
                  transform: "translateX(-50%)",
                  flexDirection: "column",
                  gap: 2,
                }),
            maxWidth: "600px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          {!isSmallScreen && <StepArrow />}
          <StepPaper {...steps[3]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 4) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            ...(isSmallScreen
              ? {
                  top: highlightBox.top + 20,
                  left: highlightBox.left + highlightBox.width + 30,
                  transform: "translateY(-50%)",
                  flexDirection: "row",
                  gap: 1,
                }
              : {
                  top: highlightBox.top + highlightBox.height + 20,
                  left: highlightBox.left + highlightBox.width / 2,
                  transform: "translateX(-50%)",
                  flexDirection: "column-reverse",
                  gap: 2,
                }),
          }}
        >
          {isSmallScreen ? (
            <>
              <StepArrow rotation={-90} />
              <StepPaper {...steps[4]} />
            </>
          ) : (
            <>
              <StepPaper {...steps[4]} />
              <StepArrow rotation={0} sx={{ marginTop: "20px" }} />
            </>
          )}
        </ContentWrapper>
      </>
    );
  }

  if (step === 5) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            ...(isSmallScreen
              ? {
                  top: highlightBox.top + 20,
                  left: highlightBox.left + highlightBox.width + 30,
                  transform: "translateY(-50%)",
                  flexDirection: "row",
                  gap: 1,
                }
              : {
                  top: highlightBox.top + highlightBox.height + 20,
                  left: highlightBox.left + highlightBox.width / 2,
                  transform: "translateX(-50%)",
                  flexDirection: "column-reverse",
                  gap: 2,
                }),
          }}
        >
          {isSmallScreen ? (
            <>
              <StepArrow rotation={-90} />
              <StepPaper {...steps[5]} />
            </>
          ) : (
            <>
              <StepPaper {...steps[5]} />
              <StepArrow rotation={0} sx={{ marginTop: "20px" }} />
            </>
          )}
        </ContentWrapper>
      </>
    );
  }

  return null;
};

export const DebriefOnboardingTour = ({
  onClose,
  currentStep = 1,
  onNext,
  onBack,
  highlightRef,
  totalSteps = 5,
}) => {
  const closeButtonTopPosition = currentStep <= 2 ? 100 : 16;

  return (
    <OnboardingTour
      onClose={onClose}
      currentStep={currentStep}
      totalSteps={totalSteps}
      onNext={onNext}
      onBack={onBack}
      highlightRef={highlightRef}
      steps={ONBOARDING_STEPS}
      renderStep={renderStep}
      closeButtonTopPosition={closeButtonTopPosition}
    />
  );
};

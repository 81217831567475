import { Box } from "@mui/material";
import { OnboardingTour } from "../common/OnboardingTour/OnboardingTour";

const ONBOARDING_STEPS = {
  1: {
    title: "Welcome to Doctrina",
    description:
      "Explore how courts use language in their opinions. Doctrina provides insights into courts' linguistic preferences and writing patterns, helping you craft more persuasive arguments.",
  },
  2: {
    title: "Court Overview Cards",
    description:
      "Each card presents a comprehensive snapshot of a court's language patterns. View key metrics like jurisprudence, sentiment, and tone analysis at a glance.",
  },
  3: {
    title: "Compare & Analyze",
    description:
      "Use these buttons to dive deeper into court analysis. Compare multiple courts side by side or explore detailed breakdowns of individual courts' language preferences.",
  },
  4: {
    title: "Search & Filter Bar",
    description:
      "Quickly find specific courts using the search bar. Enter keywords like court names or locations to narrow down your results.",
  },
  5: {
    title: "Advanced Filters",
    description:
      "Refine your court selection using advanced filters. Filter by jurisdiction or state to focus on courts most relevant to your practice.",
  },
};

const renderStep = ({
  step,
  highlightBox,
  commonHighlightStyles,
  isSmallScreen,
  StepPaper,
  StepArrow,
  ContentWrapper,
  steps,
  theme,
}) => {
  if (step === 1) {
    return (
      <>
        <Box sx={{ ...commonHighlightStyles, height: `calc(100vh - 80px)` }} />
        <ContentWrapper
          sx={{
            bottom: 100,
            left: "50%",
            transform: "translateX(-50%)",
            flexDirection: "column",
            gap: isSmallScreen ? 2 : 3,
            maxWidth: "600px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          <StepPaper {...steps[1]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 2) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            ...(isSmallScreen
              ? {
                  bottom: 80,
                  left: "50%",
                  transform: "translateX(-50%)",
                }
              : {
                  top: highlightBox.top + highlightBox.height + 40,
                  left: "50%",
                  transform: "translateX(-50%)",
                }),
            flexDirection: "column",
            gap: isSmallScreen ? 1 : 2,
            maxWidth: "600px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          {!isSmallScreen && <StepArrow />}
          <StepPaper {...steps[2]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 3) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            ...(isSmallScreen
              ? {
                  top: highlightBox.top - 75,
                  left: highlightBox.left + highlightBox.width + 50,
                }
              : {
                  top: highlightBox.top + highlightBox.height - 160,
                  left: highlightBox.left + highlightBox.width + 40,
                  transform: "translateY(0)",
                }),
            flexDirection: "row",
            gap: isSmallScreen ? 1 : 2,
            maxWidth: "500px",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <StepArrow rotation={-90} />
          </Box>
          <StepPaper {...steps[3]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 4) {
    return (
      <>
        <Box sx={commonHighlightStyles} />
        <ContentWrapper
          sx={{
            top:
              highlightBox.top +
              highlightBox.height +
              (isSmallScreen ? 20 : 40),
            left: "50%",
            transform: "translateX(-50%)",
            flexDirection: "column",
            gap: isSmallScreen ? 1 : 2,
          }}
        >
          <StepArrow />
          <StepPaper {...steps[4]} />
        </ContentWrapper>
      </>
    );
  }

  if (step === 5) {
    return (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "370px",
            height: "100vh",
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
            boxShadow: `0 0 15px 1px ${theme.palette.primary.main}`,
            zIndex: 1301,
            backgroundColor: "transparent",
            pointerEvents: "none",
            backdropFilter: "brightness(2)",
          }}
        />

        <ContentWrapper
          sx={{
            top: "50%",
            right: "400px",
            transform: "translateY(-50%)",
            flexDirection: "row",
            gap: 2,
            maxWidth: "600px",
            width: "100%",
            padding: "0 20px",
          }}
        >
          <StepPaper {...steps[5]} />
          <StepArrow rotation={90} />
        </ContentWrapper>
      </>
    );
  }

  return null;
};

export const DoctrinaOnboardingTour = ({
  onClose,
  currentStep = 1,
  onNext,
  onBack,
  highlightRef,
}) => {
  return (
    <OnboardingTour
      onClose={onClose}
      currentStep={currentStep}
      totalSteps={5}
      onNext={onNext}
      onBack={onBack}
      highlightRef={highlightRef}
      steps={ONBOARDING_STEPS}
      renderStep={renderStep}
    />
  );
};

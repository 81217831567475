import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

export const VideoModal = ({ open, onClose, title, videoUrl }) => {
  const enhancedVideoUrl = `${videoUrl}?rel=0&modestbranding=1`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 2,
          pt: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            width: "100%",
            textAlign: "center",
            pr: 4,
          }}
        >
          {title}
        </Typography>
        <Tooltip title="Close window" placement="top">
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            position: "relative",
            paddingTop: "56.25%",
            width: "100%",
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: 0,
            }}
            src={enhancedVideoUrl}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
